"use client"

import styled from "@emotion/styled"

import Image from "next/image"
import { extractProperty, findProperty, getUmbracoMediaUrl } from "utils"
import Container from "./Container"
import NewText from "./NewText/NewText"
import SectionTitle from "./SectionTitle"
import { useIsDesktop } from "./common/bit/MediaQueries"
import { sharedColors } from "./theme/colors"

const StyledLogosContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  justify-content: center;
  align-items: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;
    min-width: 115px;
    justify-content: center;
    align-items: center;
  }
`

type Props = {
  data: any
}

export const CustomerList: React.FC<Props> = ({ data }) => {
  const title = extractProperty(data, "blockTitle")
  const content = extractProperty(data, "blockContent")
  const logos = findProperty(data, "logoPicker")
  const isDesktop = useIsDesktop()
  return (
    <Container
      style={{
        paddingTop: isDesktop ? "80px" : "40px",
        paddingBottom: isDesktop ? "80px" : "40px",
      }}
    >
      <SectionTitle title={title} accentColor={sharedColors.propBlue} />
      <NewText
        desktopFontSize={19}
        desktopFontWeight="400"
        desktopLineHeight="25.92px"
        mobileFontSize={16}
        mobileFontWeight="400"
        mobileLineHeight={"125%"}
        component="div"
        color={sharedColors.black}
        maxWidth={"580px"}
        marginTop={"16px"}
        marginBottom={"24px"}
        dangerouslySetInnerHTML={{ __html: content }}
      />
      <StyledLogosContainer>
        {logos.mediaItems.map((i: any) => (
          <Image
            alt="image"
            key={i.url.toString()}
            src={getUmbracoMediaUrl(i.url)}
            width={115}
            height={80}
            style={{ margin: "0 auto" }}
          />
        ))}
      </StyledLogosContainer>
    </Container>
  )
}

export default CustomerList
