"use client"

import styled from "@emotion/styled"
import React from "react"

const StyledContainer = styled("div")<{
  withoutPadding: boolean
  isSmall: boolean
  padding: number
  forSwiper?: boolean
  borderB?: boolean
}>`
  padding-left: ${({ withoutPadding, forSwiper }) =>
    forSwiper || withoutPadding ? "0px " : "16px"};
  padding-right: ${({ withoutPadding, forSwiper }) =>
    forSwiper || withoutPadding ? "0px " : "16px"};
  border-bottom: ${(props) =>
    props.borderB
      ? `
    1px solid #6f6f6f
  `
      : "none"};
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: ${(props) => (props.forSwiper ? "1080px" : "1040px")};
    margin: auto;
    padding: 0px;
    padding-left: ${(props) => (props.padding ? `${props.padding}px` : "0px")};
    padding-right: ${(props) => (props.padding ? `${props.padding}px` : "0px")};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    width: ${(props) => (props.forSwiper ? "1320px" : "1280px")};
    margin: auto;
    padding: 0px;
    padding-left: ${(props) => (props.padding ? `${props.padding}px` : "0px")};
    padding-right: ${(props) => (props.padding ? `${props.padding}px` : "0px")};
  }
`
type Props = {
  children?: React.ReactNode
  withoutPadding?: boolean
  isSmall?: boolean
  padding?: number
  style?: React.CSSProperties
  forSwiper?: boolean
  className?: string
  borderB?: boolean
  containerRef?: React.Ref<HTMLDivElement>
  id?: string
}

export const Container: React.FC<Props> = ({
  isSmall = true,
  children,
  withoutPadding = false,
  padding = 0,
  style,
  forSwiper,
  className,
  borderB,
  containerRef,
  id,
}) => {
  return (
    <StyledContainer
      forSwiper={forSwiper}
      isSmall={isSmall}
      withoutPadding={withoutPadding}
      padding={padding}
      style={style}
      className={className}
      borderB={borderB}
      ref={containerRef}
      id={id}
    >
      {children}
    </StyledContainer>
  )
}

export default Container
