import { SVGProps } from "react"
const ContractIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="#2768A3"
      fillRule="evenodd"
      d="M5 3.833C5 2.821 5.82 2 6.833 2h18.334C26.179 2 27 2.82 27 3.833V20.65a.611.611 0 0 1-1.222 0V3.833a.611.611 0 0 0-.611-.61H6.833a.611.611 0 0 0-.61.61v24.334c0 .337.273.61.61.61h18.334a.611.611 0 0 0 .61-.61v-1.59a.611.611 0 0 0-.61-.61H9.644a.611.611 0 0 1 0-1.223h15.523c1.012 0 1.833.821 1.833 1.834v1.589C27 29.179 26.18 30 25.167 30H6.833A1.833 1.833 0 0 1 5 28.167V3.833Z"
      clipRule="evenodd"
    />
    <path
      fill="#2768A3"
      d="m15.534 8.517.1-.015a.549.549 0 0 1 .291.013c.044.014.085.031.13.054l.133.076-.006-.001.121.056.094.051c.107.071.147.158.146.37l-.008.184-.052.55a13.11 13.11 0 0 1-.448 2.196l-.045.146.06.123c.173.338.414.724.7 1.123l.177.24c.18.237.384.487.595.732l-.036-.044c.214-.015.426-.019.635-.012l.311.02.245.025c.244.03.488.076.716.133.331.085.581.183.804.329.266.174.48.408.593.671.127.292.133.627.012.892-.113.253-.327.419-.594.507a1.244 1.244 0 0 1-.784.002c-.255-.08-.54-.24-.853-.472a7.479 7.479 0 0 1-.973-.87l-.13-.137-.274-.3c-.103.01-.21.025-.323.042l-.176.03-.288.052c-.508.095-.91.19-1.401.34l-.297.094-.3.103-.155.055-.082.131c-.453.7-.883 1.242-1.264 1.612l-.14.13c-.345.305-.637.453-.922.503-.289.052-.52-.023-.65-.275-.092-.177-.107-.41-.055-.645.058-.272.199-.523.462-.79.303-.309.75-.625 1.292-.9.208-.105.44-.21.692-.312l.194-.076c.53-.86.995-1.787 1.312-2.658l.087-.246.033-.104a5.99 5.99 0 0 1-.25-.75 6.362 6.362 0 0 1-.205-1.06 4.01 4.01 0 0 1 .016-.887c.036-.282.095-.49.2-.654a.842.842 0 0 1 .415-.335.723.723 0 0 1 .145-.042Zm-2.586 7.945-.12.07c-.3.182-.544.37-.719.548-.138.141-.21.25-.245.355l-.01.034a1.12 1.12 0 0 0 .25-.138l.093-.071.102-.086c.12-.106.248-.236.383-.39l.138-.162.128-.16Zm5.216-1.344.107.108c.261.26.487.455.718.624.254.189.475.313.642.365a.55.55 0 0 0 .357.002c.091-.03.141-.069.16-.11.026-.058.024-.162-.019-.26a.846.846 0 0 0-.317-.342c-.148-.097-.33-.168-.59-.235a5.454 5.454 0 0 0-.56-.107l-.29-.033-.208-.012Zm-2.497-1.986-.053.138c-.111.273-.234.548-.366.824l-.205.412-.204.382.265-.078c.196-.055.383-.102.575-.146l.293-.063.309-.061.336-.061-.061-.075-.197-.254a12.027 12.027 0 0 1-.535-.764l-.157-.254Zm.144-3.844-.072-.04-.083.013-.013.005a.134.134 0 0 0-.067.048c-.03.048-.062.157-.085.335-.029.22-.035.463-.012.714.01.107.026.221.047.342l.044.221.015-.063c.042-.2.079-.393.109-.576l.04-.266.068-.618.009-.115Z"
    />
  </svg>
)
export default ContractIcon
