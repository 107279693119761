"use client"
import { styled } from "@mui/material"
import Link from "next/link"
import NewText from "./NewText/NewText"
import { sharedColors } from "./theme/colors"

const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  svg {
    ${(props) => props.theme.breakpoints.up("md")} {
      width: 20px;
      height: 20px;
    }
    ${(props) => props.theme.breakpoints.up("lg")} {
      width: 28px;
      height: 28px;
    }
    margin-right: 10px;
  }
`

type LinkComponentProps = {
  text: string
  icon?: JSX.Element
  link: string
  fontSize: number
  color?: string
  mobileFontSize?: number
  desktopLineHeight?: string
  mobileLineHeight?: string
  fontWeight?: number
  mobileFontWeight?: number
  mediumFontSize?: number
  sx?: any
}

export const LinkComponent = ({
  text,
  icon,
  link = "",
  fontSize,
  color = sharedColors.blue,
  mobileFontSize = fontSize,
  desktopLineHeight = "24px",
  mobileLineHeight = desktopLineHeight,
  fontWeight = 400,
  mobileFontWeight = fontWeight,
  mediumFontSize = fontSize,
  sx,
}: LinkComponentProps) => {
  return (
    <StyledLink href={link}>
      {icon ? icon : null}
      <NewText
        component={"p"}
        desktopFontSize={fontSize}
        desktopLineHeight={desktopLineHeight}
        color={color}
        mobileFontSize={mobileFontSize}
        mobileLineHeight={mobileLineHeight}
        desktopFontWeight={fontWeight}
        mobileFontWeight={mobileFontWeight}
        smallDesktopFontSize={mediumFontSize}
        smallDesktopLineHeight="16px"
        sx={sx}
      >
        {text}
      </NewText>
    </StyledLink>
  )
}

export default LinkComponent
