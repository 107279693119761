import { SVGProps } from "react"

const CheckboxIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <rect
      width={23}
      height={23}
      x={0.5}
      y={0.5}
      fill="#fff"
      stroke={props.color || "#6F6F6F"}
      rx={3.5}
    />
  </svg>
)
export default CheckboxIcon
