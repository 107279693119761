import { SVGProps } from "react"
const BannerTopIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="157"
    height="180"
    viewBox="0 0 157 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M65.5373 6.64247C63.258 2.39918 58.831 -0.248047 54.0142 -0.248047H-5.8146C-16.9627 -0.248047 -26 8.78929 -26 19.9374V110.454C-26 113.209 -24.2743 115.668 -21.684 116.605L151.837 179.355C154.658 180.376 157.249 177.376 155.83 174.733L65.5373 6.64247Z"
      fill="url(#paint0_linear_1_162)"
      fill-opacity="0.24"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1_162"
        x1="66.9371"
        y1="-0.248047"
        x2="66.9371"
        y2="182.262"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
)
export default BannerTopIcon
