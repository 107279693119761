import { SVGProps } from "react"
const LinkedinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <rect width={24} height={24} y={0.278} fill="#0A66C2" rx={4} />
    <path
      fill="#fff"
      d="M20 20.278v-5.86c0-2.88-.62-5.08-3.98-5.08-1.62 0-2.7.88-3.14 1.72h-.04v-1.46H9.66v10.68h3.32v-5.3c0-1.4.26-2.74 1.98-2.74 1.7 0 1.72 1.58 1.72 2.82v5.2H20v.02ZM4.26 9.598h3.32v10.68H4.26V9.598ZM5.92 4.278c-1.06 0-1.92.86-1.92 1.92s.86 1.94 1.92 1.94 1.92-.88 1.92-1.94-.86-1.92-1.92-1.92Z"
    />
  </svg>
)
export default LinkedinIcon
