const NotFoundIcon = () => (
  <svg
    width="136"
    height="160"
    viewBox="0 0 136 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M116.155 147.1H19.8516L21.604 140.768L25.0259 128.407L27.0973 120.922L30.1526 109.888L36.0416 88.6149L39.0969 77.5786L60.5752 0H75.4312L83.3025 28.431L96.9095 77.5786L99.9648 88.6149L105.854 109.888L108.909 120.922L114.402 140.768L116.155 147.1Z"
      fill="#FF9D00"
    />
    <path
      opacity="0.16"
      d="M116.155 147.099H19.8516L21.604 140.768L25.0259 128.406C92.1349 124.45 83.3046 28.4326 83.3046 28.4326L114.404 140.77L116.157 147.101L116.155 147.099Z"
      fill="black"
    />
    <path d="M135.856 147.1H0.144531V159.976H135.856V147.1Z" fill="#FF9D00" />
    <path
      d="M116.155 147.099H19.8516L21.604 140.768H114.402L116.155 147.099Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M99.9623 88.6154H36.0391L39.0944 77.5791H96.907L99.9623 88.6154Z"
      fill="white"
    />
    <path
      d="M108.909 120.922H27.0977L30.153 109.888H105.854L108.909 120.922Z"
      fill="white"
    />
    <path
      opacity="0.16"
      d="M104.468 147.1C104.468 147.1 86.48 160.652 19.8516 159.976H135.86V147.1H104.47H104.468Z"
      fill="black"
    />
  </svg>
)
export default NotFoundIcon
