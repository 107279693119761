import { SVGProps } from "react"

const Unmute = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_362_11261)">
        <rect x="8" y="4" width="32" height="32" rx="16" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.9011 14.7115C25.5181 14.1785 26.4759 14.6168 26.4759 15.4321V19.9997V24.5673C26.4759 25.3826 25.5181 25.8209 24.9011 25.2879L22.3647 23.0969C21.9841 22.7681 21.498 22.5872 20.9952 22.5872H19.8099C19.0736 22.5872 18.4767 21.9903 18.4767 21.254V18.7454C18.4767 18.0091 19.0736 17.4122 19.8099 17.4122L20.9951 17.4122C21.498 17.4122 21.9841 17.2313 22.3647 16.9025L24.9011 14.7115ZM27.6186 15.4321C27.6186 13.6384 25.5114 12.6741 24.154 13.8467L21.6176 16.0378C21.4447 16.1872 21.2237 16.2694 20.9951 16.2694L19.8099 16.2694C18.4425 16.2694 17.334 17.378 17.334 18.7454V21.254C17.334 22.6214 18.4425 23.73 19.8099 23.73H20.9952C21.2237 23.73 21.4447 23.8122 21.6177 23.9616L24.154 26.1527C25.5114 27.3252 27.6186 26.361 27.6186 24.5673V19.9997V15.4321ZM29.7895 17.3714C29.5999 17.1191 29.2418 17.0682 28.9895 17.2578C28.7372 17.4474 28.6864 17.8055 28.8759 18.0578C29.2825 18.5989 29.5232 19.2706 29.5232 20.0001C29.5232 20.7295 29.2825 21.4012 28.8759 21.9423C28.6864 22.1946 28.7372 22.5528 28.9895 22.7423C29.2418 22.9319 29.5999 22.881 29.7895 22.6288C30.3397 21.8966 30.6659 20.9856 30.6659 20.0001C30.6659 19.0145 30.3397 18.1035 29.7895 17.3714Z"
          fill="black"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_362_11261"
          x="0"
          y="0"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_362_11261"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_362_11261"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default Unmute
