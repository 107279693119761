const StartIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M14.6794 7.39248L14.6816 7.39371C14.7625 7.43917 14.8252 7.50088 14.8668 7.5696C14.9083 7.638 14.9286 7.71232 14.9286 7.78571C14.9286 7.85911 14.9083 7.93343 14.8668 8.00183C14.8252 8.07055 14.7625 8.13226 14.6816 8.17772L14.6794 8.17896L5.33059 13.4912L5.33056 13.4912L5.32357 13.4953C5.24379 13.5423 5.14875 13.5696 5.04905 13.5713C4.94934 13.5731 4.85291 13.5491 4.77075 13.5045L4.77083 13.5044L4.75979 13.4988C4.67474 13.4553 4.60857 13.3938 4.56461 13.3244L4.14228 13.5921L4.56461 13.3244C4.52089 13.2554 4.49976 13.18 4.5 13.1056V13.104V2.46742H4.50001L4.5 2.46579C4.49976 2.39144 4.52089 2.31598 4.56461 2.24699C4.60857 2.17763 4.67474 2.11609 4.75979 2.07265L4.75986 2.07279L4.77075 2.06688C4.85291 2.02232 4.94934 1.99836 5.04905 2.00009C5.14875 2.00182 5.24379 2.02909 5.32357 2.07615L5.32353 2.07621L5.33059 2.08022L14.6794 7.39248ZM4 2.46742V13.104V2.46742Z"
      stroke="black"
    />
  </svg>
)
export default StartIcon
