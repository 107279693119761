import { SVGProps } from "react"
const ShareIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "16"}
    height={props.height || "16"}
    viewBox="0 0 16 17"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.168 2.28125C11.5927 2.28125 11.1263 2.74762 11.1263 3.32292C11.1263 3.89821 11.5927 4.36458 12.168 4.36458C12.7433 4.36458 13.2096 3.89821 13.2096 3.32292C13.2096 2.74762 12.7433 2.28125 12.168 2.28125ZM9.8763 3.32292C9.8763 2.05726 10.9023 1.03125 12.168 1.03125C13.4336 1.03125 14.4596 2.05726 14.4596 3.32292C14.4596 4.58857 13.4336 5.61458 12.168 5.61458C11.5617 5.61458 11.0103 5.37912 10.6005 4.99465L6.38121 7.40566C6.43152 7.59443 6.45833 7.79278 6.45833 7.99739C6.45833 8.36602 6.3713 8.71432 6.21665 9.02287L10.5992 11.6524C11.0092 11.2672 11.561 11.0312 12.168 11.0312C13.4336 11.0312 14.4596 12.0573 14.4596 13.3229C14.4596 14.5886 13.4336 15.6146 12.168 15.6146C10.9023 15.6146 9.8763 14.5886 9.8763 13.3229C9.8763 13.1156 9.90382 12.9148 9.9554 12.7239L5.34975 9.96046C5.00439 10.169 4.59955 10.2891 4.16667 10.2891C2.90101 10.2891 1.875 9.26305 1.875 7.99739C1.875 6.73174 2.90101 5.70573 4.16667 5.70573C4.77626 5.70573 5.33026 5.94374 5.74081 6.33191L9.95577 3.92336C9.90395 3.73199 9.8763 3.53068 9.8763 3.32292ZM4.16667 6.95573C3.59137 6.95573 3.125 7.4221 3.125 7.99739C3.125 8.57269 3.59137 9.03906 4.16667 9.03906C4.74196 9.03906 5.20833 8.57269 5.20833 7.99739C5.20833 7.4221 4.74196 6.95573 4.16667 6.95573ZM11.1263 13.3229C11.1263 12.7476 11.5927 12.2812 12.168 12.2812C12.7433 12.2812 13.2096 12.7476 13.2096 13.3229C13.2096 13.8982 12.7433 14.3646 12.168 14.3646C11.5927 14.3646 11.1263 13.8982 11.1263 13.3229Z"
      fill={props.color || "black"}
    />
  </svg>
)
export default ShareIcon
