import { gql } from "@apollo/client"

const GET_PAGES_WITH_OLD_URLS = gql`
  query GetPagesWithOldURLs($route: String!, $culture: String!) {
    components: contentDescendantsByAbsoluteRoute(route: $route, culture: $culture) {
      edges {
        node {
          url
          properties {
            propertyName: alias
            propertyValue: value {
              ... on BasicMultiUrlPicker {
                links {
                  url
                }
              }
            }
          }
          children {
            url
            properties {
              propertyName: alias
              propertyValue: value {
                ... on BasicMultiUrlPicker {
                  links {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export default GET_PAGES_WITH_OLD_URLS
