"use client"

import { extractProperty } from "utils"
import Container from "../Container"
import SectionTitle from "../SectionTitle"
import { useIsDesktop } from "../common/bit/MediaQueries"
import { sharedColors } from "../theme/colors"
import ActivitySwiper from "./ActivitySwiper"

type Props = {
  data: any
}

export const Activities: React.FC<Props> = ({ data }) => {
  const title = extractProperty(data, "blockTitle")
  const sliderContent = extractProperty(data, "slidContent").map((item: any) => ({
    logo: extractProperty(item, "logo"),
    content: extractProperty(item, "content"),
    link: extractProperty(item, "link"),
  }))
  const isDesktop = useIsDesktop()
  return (
    <Container
      style={{
        paddingTop: isDesktop ? "80px" : "40px",
        paddingBottom: isDesktop ? "80px" : "40px",
      }}
    >
      <SectionTitle title={title} accentColor={sharedColors.propBlue} />
      <ActivitySwiper data={sliderContent} />
    </Container>
  )
}

export default Activities
