"use client"
import styled from "@emotion/styled"
import { extractImage } from "utils"
import ImageWithAlt from "./ImageWithAlt"

const StyledContainer = styled.div`
  height: 896px;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) => props.theme.breakpoints.down("md")} {
    height: 392px;
  }
`

const StyledCircle = styled.div`
  width: 800px;
  height: 800px;
  border-radius: 100%;
  border: 1px solid #000;
  position: absolute;
  right: -10%;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 350px;
    height: 350px;
  }
`

const StyledFirstImage = styled.div`
  position: absolute;
  width: 563px;
  height: 563px;
  border-radius: 100%;
  transition: 500ms;
  transition-timing-function: ease-in;
  left: 0px;
  font-size: 10rem;
  img {
    border-radius: 100%;
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 270px;
    height: 270px;
  }
`

type Props = {
  imagesData: any
  moveNext: () => void
  moveBack: () => void
  active: number
}

function CircularGallery({ imagesData, active }: Props) {
  const images = imagesData.map((imageData: any) => extractImage(imageData, "slidImage"))

  const getTransform = (index: number, activeIndex: number) => {
    if (index === activeIndex) {
      return `translate(50%,25%)`
    }

    if (index === activeIndex + 2) {
      return `translate(-250px,490px) scale(0.05)`
    }

    if (index === activeIndex + 1) {
      return `translate(-50px,490px) scale(0.25)`
    }

    if (index === activeIndex - 1) {
      return `translate(-50px,-275px) scale(0.15)`
    }

    if (index === activeIndex - 2) {
      return `translate(-500px,-275px) scale(0.15)`
    }

    if (index < activeIndex) {
      return `translate(-500px,-275px) scale(0.15)`
    }

    if (index > activeIndex) {
      return `translate(-500px,500px) scale(0.25)`
    }
  }

  const getBackgroundColor = (index: number, activeIndex: number) => {
    if (index === activeIndex) {
      return `transparent`
    }

    if (index === activeIndex + 1) {
      return `#FFBD02`
    }

    if (index < activeIndex) {
      return `black`
    }

    if (index > activeIndex) {
      return `#FFBD02`
    }
  }

  return (
    <StyledContainer>
      <StyledCircle>
        {images.map((image: any, index: number) => (
          <StyledFirstImage
            key={index.toString()}
            style={{
              transform: getTransform(index, active),
            }}
          >
            <div
              style={{
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                borderRadius: "100%",
                position: "absolute",
                zIndex: 5,
                backgroundColor: getBackgroundColor(index, active),
              }}
            />
            <ImageWithAlt fill image={image} />
          </StyledFirstImage>
        ))}
      </StyledCircle>
    </StyledContainer>
  )
}

export default CircularGallery
