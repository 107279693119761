import { SVGProps } from "react"

const MenuArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={8}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fill={props.fill}
      d="m.21 7.483 5.795 5.526a.74.74 0 0 0 1.012 0l.676-.645a.66.66 0 0 0 0-.964L3.102 7l4.593-4.4a.66.66 0 0 0-.001-.965L7.017.991a.74.74 0 0 0-1.012 0L.21 6.517a.66.66 0 0 0 0 .966Z"
    />
  </svg>
)
export default MenuArrowRight
