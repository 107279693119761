"use client"
import styled from "@emotion/styled"
import Image from "next/image"
import { getUmbracoMediaUrl } from "utils"
import { useIsDesktop, useIsSmall } from "./common/bit/MediaQueries"

const StyledImagesContainer = styled.div<{
  $height: number
  $mediumHeight: number
  $mobileHeight?: number
  $width?: number
  $mediumWidth?: number
  $mobileWidth?: number
}>`
  position: relative;
  ${(props) => props.theme.breakpoints.down("sm")} {
    height: ${(p) => (p.$mobileHeight ? `${p.$mobileHeight}px` : "480px")};
    width: ${(p) => (p.$mobileWidth ? `${p.$mobileWidth}px` : "100%")};
  }

  ${(props) => props.theme.breakpoints.up("sm")} {
    height: ${(p) => (p.$mediumHeight ? `${p.$mediumHeight}px` : "890px")};
    width: ${(p) => (p.$mediumWidth ? `${p.$mediumWidth}px` : "100%")};
  }

  ${(props) => props.theme.breakpoints.up("lg")} {
    height: ${(p) => (p.$height ? `${p.$height}px` : "890px")};
    width: ${(p) => (p.$width ? `${p.$width}px` : "100%")};
  }
`

type ImageContainerProps = {
  height: number
  desktopUrl: string
  mobileUrl?: string
  alt?: string
  mobileHeight?: number
  mobileWidth?: number
  children?: JSX.Element
  width?: number
  mediumHeight?: number
  mediumWidth?: number
  useSmall?: boolean
  borderRadius?: number
  objectFit?: "cover" | "contain"
}

export const ImageContainer = ({
  height,
  desktopUrl,
  mobileUrl = desktopUrl,
  alt,
  mobileHeight,
  children,
  width,
  mediumHeight = height,
  mediumWidth = width,
  mobileWidth,
  useSmall = false,
  borderRadius = 0,
  objectFit = "cover",
}: ImageContainerProps) => {
  const isDesktop = useIsDesktop()
  const isSmall = useIsSmall()
  let imageUrl = desktopUrl
  if (!isDesktop) {
    if (isSmall && useSmall) {
      imageUrl = desktopUrl
    } else {
      imageUrl = mobileUrl
    }
  }
  return (
    <StyledImagesContainer
      $height={height}
      $mobileHeight={mobileHeight}
      $width={width}
      $mediumHeight={mediumHeight}
      $mediumWidth={mediumWidth}
      $mobileWidth={mobileWidth}
    >
      <Image
        style={{ objectFit, borderRadius: `${borderRadius}px` }}
        src={getUmbracoMediaUrl(imageUrl)}
        alt={alt ? alt : "image"}
        fill
      />
      {children}
    </StyledImagesContainer>
  )
}

export default ImageContainer
