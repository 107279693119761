"use client"
import styled from "@emotion/styled"
import { Fragment } from "react"
import { useInView } from "react-intersection-observer"
import NewText from "../NewText/NewText"
import { Desktop, Mobile } from "../common/bit/MediaQueries"
import TransitionNumbersCounter from "../transitions/TransitionNumbersCounter"
import { useInViewConfig } from "../transitions/utils"

const StyledContainer = styled.div<{ itemsNumber: number }>`
  padding: 0px;
  display: grid;
  grid-template-columns: 1fr 0px 1fr 0px 1fr;
  /* grid-template-rows: ${(props) =>
    props.itemsNumber && props.itemsNumber > 6
      ? "182px 1px 182px 1px 182px"
      : "182px 1px 182px"}; */
  gap: 5.5px;
  row-gap: 11px;
  margin-top: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 40px;
  }
`

const DescriptionWrapper = styled.div``

const StyledGridItem = styled.div<{
  $backgroundColor: string
  $color: string
  $hasMargin: boolean
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0px;
  background: ${(p) => p.$color};
  margin-left: ${(p) => (p.$hasMargin ? "0px" : "34.5px")};
  border-right: 2px solid ${(p) => p.$backgroundColor};
  height: 180px;

  :nth-of-type(3n) {
    border-right: none;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 20px;
    /* justify-content: center; */
  }
`

const StyledLine = styled.span<{
  $backgroundColor: string
  fullWidth: boolean
}>`
  height: 2px;
  width: ${(p) => (p.fullWidth ? "100%" : "calc(100% - 12px)")};
  overflow: hidden;
  background: ${(p) => p.$backgroundColor};
`

const StyledMobileContainer = styled.div<{ $template?: string }>`
  margin-top: 32px;
  padding: 0px;
  display: grid;
  grid-template-columns: ${(p) => p.$template};
  column-gap: 40px;
  row-gap: 30px;
`

const StyledMobileGridItem = styled.div<{
  $backgroundColor: string
  $color: string
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  margin-bottom: 0px;
  background: ${(p) => p.$color};
  background-color: ${(p) => p.$backgroundColor};
  width: 100%;
  align-items: stretch;
  /* display: grid; */
  /* grid-template-rows: 1fr 1fr; */
`

const StyledMobileTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  flex-grow: 1;
  padding-bottom: 5px;
  div {
    /* margin-top: 20px; */
    margin: 0;
  }
`
const StyledBottomText = styled(NewText)`
  p {
    margin: 0;
    margin-top: 5px;
  }
`

const StyledRichTextWrapper = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 92px;
  }
  p {
    margin: 0px;
    margin-top: 8px;
    ${(props) => props.theme.breakpoints.up("md")} {
      margin-top: 4px;
    }
    line-height: 20px;
    ${(props) => props.theme.breakpoints.up("md")} {
      line-height: 32px;
    }
    &:first-child {
      margin-top: 16px;
    }
  }
`

type Props = {
  data: {
    title?: string
    number?: number | string
    textNextToNumber?: string
    text?: string
  }[]
  accentColor: string
  background?: string
  textColor?: string
  backgroundColor?: string
}

const NumbersGrid: React.FC<Props> = ({
  data,
  accentColor,
  backgroundColor = "white",
  background = "white",
  textColor = "black",
}) => {
  const numbersInViewConfig = {
    ...useInViewConfig,
    // rootMargin: undefined,
    threshold: 0,
    delay: 500,
    initialInView: false,
    trackVisibility: true,
  }
  const { ref, inView } = useInView(numbersInViewConfig)
  const { ref: MobileRef, inView: MobileInView } =
    useInView(numbersInViewConfig)
  const itemsNumber = data.length

  return (
    <>
      <Desktop>
        <StyledContainer ref={ref} itemsNumber={itemsNumber}>
          {data.map((item, index) => (
            <Fragment key={index.toString() + "D"}>
              <StyledGridItem
                $hasMargin={index % 3 === 0}
                $backgroundColor={accentColor}
                style={{ background: backgroundColor }}
                $color={background}
              >
                <NewText
                  desktopFontSize={48}
                  desktopFontWeight="600"
                  desktopLineHeight="56px"
                  mobileFontSize={40}
                  mobileFontWeight="600"
                  mobileLineHeight="110%"
                  component="div"
                  color={textColor}
                  display={"flex"}
                  alignItems={"flex-end"}
                >
                  <NewText
                    desktopFontSize={
                      // typeof item?.number === "string" &&
                      // item?.number?.length > 5
                      //   ? 32
                      //   : 54
                      48
                    }
                    desktopFontWeight="600"
                    desktopLineHeight="1"
                    mobileFontSize={40}
                    mobileFontWeight="400"
                    mobileLineHeight="44px"
                    component="p"
                    color={textColor}
                  >
                    <TransitionNumbersCounter
                      inView={inView}
                      number={item.number || 0}
                    />
                  </NewText>
                  <NewText
                    desktopFontSize={20}
                    desktopFontWeight="400"
                    desktopLineHeight="1"
                    mobileFontSize={16}
                    mobileFontWeight="400"
                    mobileLineHeight="20px"
                    component="p"
                    color={textColor}
                    marginLeft={"8px"}
                    marginBottom={"7px"}
                  >
                    {item.textNextToNumber}
                  </NewText>
                </NewText>
                <StyledRichTextWrapper>
                  <NewText
                    desktopFontSize={20}
                    desktopFontWeight="400"
                    desktopLineHeight="28px"
                    mobileFontSize={16}
                    mobileFontWeight="400"
                    mobileLineHeight="20px"
                    component="div"
                    color={textColor}
                    dangerouslySetInnerHTML={{ __html: item.text || "" }}
                    margin={"8px 2px 8px 0"}
                  />
                </StyledRichTextWrapper>
                {item.title && (
                  <DescriptionWrapper>
                    <NewText
                      desktopFontSize={18}
                      desktopFontWeight="400"
                      desktopLineHeight="100%"
                      mobileFontSize={16}
                      mobileFontWeight="400"
                      mobileLineHeight="100%"
                      component="p"
                      color={textColor}
                      marginLeft={"4px"}
                    >
                      {item.title}
                    </NewText>
                  </DescriptionWrapper>
                )}
              </StyledGridItem>
              {(index + 1) % 3 !== 0 && data.length > 3 && <span />}
              {data.length <= 3 && <span />}
              {data.length > 3 &&
                (index + 1) % 3 === 0 &&
                index < data.length - 1 &&
                Array(5)
                  .fill(1)
                  .map((_, index) => (
                    <StyledLine
                      fullWidth={index % 4 === 0 && index !== 0}
                      key={index.toString()}
                      $backgroundColor={accentColor}
                    />
                  ))}
            </Fragment>
          ))}
        </StyledContainer>
      </Desktop>
      <Mobile>
        <StyledMobileContainer
          ref={MobileRef}
          $template={data.length <= 3 ? "70%" : "1fr 1fr"}
        >
          {data.map((item, index) => (
            <Fragment key={index.toString() + "M"}>
              <StyledMobileGridItem
                $backgroundColor={backgroundColor}
                $color={background}
              >
                <StyledMobileTextWrapper
                  style={{ borderBottom: `2px solid ${accentColor} ` }}
                >
                  <NewText
                    component="p"
                    desktopFontSize={48}
                    desktopFontWeight="600"
                    desktopLineHeight="107.143%"
                    mobileFontSize={
                      // typeof item?.number === "string" && item?.number?.length > 5
                      //   ? 24
                      //   : 40
                      40
                    }
                    mobileFontWeight="600"
                    mobileLineHeight="44px"
                    color={textColor}
                  >
                    <TransitionNumbersCounter
                      inView={MobileInView}
                      number={item.number || 0}
                    />
                  </NewText>
                  {item.textNextToNumber && (
                    <NewText
                      desktopFontSize={18}
                      desktopFontWeight="400"
                      desktopLineHeight="100%"
                      mobileFontSize={16}
                      mobileFontWeight="400"
                      mobileLineHeight="100%"
                      component="div"
                      color={textColor}
                      width="100%"
                      paddingBottom="8px"
                      marginLeft={"4px"}
                    >
                      {item.textNextToNumber}
                    </NewText>
                  )}
                </StyledMobileTextWrapper>
                <StyledBottomText
                  desktopFontSize={18}
                  desktopFontWeight="400"
                  desktopLineHeight="100%"
                  mobileFontSize={18}
                  mobileFontWeight="400"
                  mobileLineHeight="26px"
                  component="div"
                  color={textColor}
                  marginBottom={"8px"}
                  marginLeft={"0"}
                  width={"100%"}
                  height={"4.5em"}
                  dangerouslySetInnerHTML={{ __html: item.text || "" }}
                />
              </StyledMobileGridItem>
            </Fragment>
          ))}
        </StyledMobileContainer>
      </Mobile>
    </>
  )
}
export default NumbersGrid
