"use client"
import { GetSearchQuery, IPageInfoBlock, IProject } from "apollo"
import { getNonUmbracoUrl } from "./getNonUmbracoUrl"

function extractTextAndTruncate(maxLength: number, htmlString?: string) {
  if (!htmlString) {
    return ""
  }
  const div = document.createElement("div")
  div.innerHTML = htmlString
  let text = div.textContent || div.innerText || ""

  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "..."
  }
  return text
}

type Result = {
  name: string | undefined
  type: string
  text?: string
  title: string | undefined
  image?: string | undefined
  url: string
}[]
export function extractSearchResults(
  groupId: string[],
  desktop: boolean,
  locale: string,
  data?: GetSearchQuery,
) {
  if (!data) return null
  const projectNodes = data.projects?.nodes ?? []
  const projectsData: Result = projectNodes
    .filter((el) => {
      console.log(el?.id, "CHECKTHIS1")
      const properties = el?.namedProperties as IProject
      const projectType = properties.projectType?.value
      const entitledProject = projectType === "זכאים"
      const groups = properties.groupSIte?.contentList ?? []
      return groups.some((group) => groupId.includes(group.key)) && !entitledProject
    })
    .map((el) => {
      const properties = el?.namedProperties as IProject
      desktop ? 95 : 25
      return {
        name: properties.projectTitle?.value as string | undefined,
        type: locale === "en" ? "Project" : "פרויקטים",
        text: extractTextAndTruncate(desktop ? 95 : 25, properties.text?.value as string),
        title: properties.projectTitle?.value as string | undefined,
        image: properties.thumbnailImageForLobbyPage?.mediaItems[0]?.url,
        url: getNonUmbracoUrl(el?.url || ""),
      }
    })

  const pageNodes = data.pages?.nodes ?? []
  const pagesData: Result = pageNodes.map((el) => {
    let type: string = locale === "en" ? "Page" : "דפים"
    if (el?.url?.includes("article")) {
      type = locale === "en" ? "Article" : "כתבות"
    }
    const properties = el?.namedProperties as IPageInfoBlock
    return {
      name: properties.titleBreadcrumbs?.value as string | undefined,
      type,
      title: properties.titleBreadcrumbs?.value as string | undefined,
      url: getNonUmbracoUrl(el?.url || ""),
    }
  })
  const returnArray = [...projectsData, ...pagesData].slice(0, 10)
  return returnArray
}
