import * as React from "react"
import { SVGProps } from "react"
const AccordionButtonIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="m11.97 7.769-6.632 6.954a.889.889 0 0 0 0 1.214l.774.812a.792.792 0 0 0 1.157 0l5.28-5.51 5.28 5.51a.792.792 0 0 0 1.158 0l.773-.812a.888.888 0 0 0 0-1.214l-6.632-6.954a.792.792 0 0 0-1.158 0Z"
    />
  </svg>
)
export default AccordionButtonIcon
