import React, { SVGProps } from "react"
import Unmute from "./Unmute"
import UnmuteHover from "./UnmuteHover"

const VideoUnmuteIcon = (props: SVGProps<SVGSVGElement>) => {
  const [isHovered, setIsHovered] = React.useState(false)
  return (
    <span
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      {!isHovered ? <Unmute {...props} /> : <UnmuteHover {...props} />}
    </span>
  )
}

export default VideoUnmuteIcon
