import { SVGProps } from "react"

const MenuArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={9}
    fill="none"
    {...props}
  >
    <path
      fill={props.fill}
      d="M6.902 8.604.415 2.117 1.93.604l4.973 4.973L11.875.604l1.513 1.513-6.486 6.487Z"
    />
  </svg>
)
export default MenuArrowUp
