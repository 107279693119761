import { SVGProps } from "react"
const LeftMobileArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={14}
    fill="none"
    {...props}
  >
    <path
      stroke="#111827"
      strokeLinejoin="bevel"
      d="M21.88 7H.503m0 0 6-6m-6 6 6 6"
    />
  </svg>
)
export default LeftMobileArrow
