import { SVGProps } from "react"
const RightMobileArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={14}
    fill="none"
    {...props}
  >
    <path
      stroke="#111827"
      strokeLinejoin="bevel"
      d="M.88 7h21.374m0 0-6-6m6 6-6 6"
    />
  </svg>
)
export default RightMobileArrow
