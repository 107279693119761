const SearchIconNew = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.91699 8.79505C2.91699 5.53303 5.62194 2.88864 8.95866 2.88864C12.2954 2.88864 15.0003 5.53303 15.0003 8.79505C15.0003 12.0571 12.2954 14.7015 8.95866 14.7015C5.62194 14.7015 2.91699 12.0571 2.91699 8.79505ZM8.95866 1.66663C4.93158 1.66663 1.66699 4.85813 1.66699 8.79505C1.66699 12.732 4.93158 15.9235 8.95866 15.9235C10.6497 15.9235 12.2063 15.3607 13.4432 14.4163L17.2667 18.1543C17.5108 18.3929 17.9065 18.3929 18.1506 18.1543C18.3947 17.9157 18.3947 17.5288 18.1506 17.2902L14.3597 13.5841C15.5345 12.3187 16.2503 10.6386 16.2503 8.79505C16.2503 4.85813 12.9857 1.66663 8.95866 1.66663Z"
      fill="black"
    />
  </svg>
)
export default SearchIconNew
