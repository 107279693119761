import { SVGProps } from "react"
const DividerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={1}
    height={18}
    fill="none"
    {...props}
  >
    <path stroke="#000" strokeLinecap="round" d="M.5 1v16" />
  </svg>
)
export default DividerIcon
