import * as React from "react"
const ContactCalling = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M11.949 2.087a.64.64 0 0 1 .82-.383 9.146 9.146 0 0 1 3.356 2.132 9.106 9.106 0 0 1 2.172 3.437.64.64 0 0 1-1.209.422 7.83 7.83 0 0 0-1.868-2.957 7.865 7.865 0 0 0-2.887-1.834.638.638 0 0 1-.384-.817Zm-8.33.395c1.023-1.019 2.678-.972 3.697.044l1.702 1.696c1.019 1.016 1.066 2.666.044 3.685l-.236.235a.495.495 0 0 0-.028.668c.357.43.739.857 1.151 1.268.412.41.84.791 1.271 1.146.205.169.493.15.671-.028l.236-.235c1.022-1.018 2.677-.971 3.697.045l1.701 1.696c1.02 1.016 1.067 2.665.045 3.684l-1.099 1.095c-1.116 1.113-2.942 1.023-4.72.373-1.832-.67-3.877-2.018-5.728-3.863-1.852-1.845-3.204-3.884-3.876-5.71-.652-1.771-.742-3.592.374-4.704L3.62 2.482Zm2.792.947c-.547-.545-1.391-.538-1.886-.045L3.426 4.48c-.552.55-.692 1.69-.077 3.362.595 1.616 1.828 3.503 3.579 5.247 1.75 1.745 3.643 2.975 5.265 3.568 1.677.613 2.82.473 3.373-.078l1.098-1.094c.495-.493.503-1.335-.044-1.88l-1.702-1.696c-.547-.545-1.39-.537-1.885-.044l-.236.235c-.642.639-1.674.7-2.392.11a19.244 19.244 0 0 1-1.362-1.229 19.203 19.203 0 0 1-1.232-1.357 1.768 1.768 0 0 1 .11-2.384l.236-.235c.495-.493.502-1.334-.045-1.88L6.411 3.43Zm5.553 1.516a.641.641 0 0 1 .843-.33c.548.238 1.06.58 1.508 1.026a4.876 4.876 0 0 1 1.07 1.601.641.641 0 0 1-1.185.484 3.601 3.601 0 0 0-.79-1.183 3.617 3.617 0 0 0-1.115-.758.637.637 0 0 1-.331-.84Z"
      clipRule="evenodd"
    />
  </svg>
)
export default ContactCalling
