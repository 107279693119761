import { SVGProps } from "react"
const RightArrow = ({ fill = "black" }: SVGProps<SVGSVGElement>) => (
  <svg
    width="9"
    height="13"
    viewBox="0 0 9 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.23962 6.01739L2.44447 0.490899C2.16501 0.224387 1.7119 0.224387 1.4324 0.490899L0.756493 1.13548C0.477464 1.40157 0.47694 1.83282 0.755299 2.09952L5.34804 6.49999L0.755299 10.9005C0.47694 11.1672 0.477464 11.5984 0.756493 11.8645L1.4324 12.5091C1.71187 12.7756 2.16498 12.7756 2.44447 12.5091L8.23962 6.98258C8.51908 6.71604 8.51908 6.28393 8.23962 6.01739Z"
      fill={fill}
    />
  </svg>
)
export default RightArrow
