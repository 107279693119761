import { getUmbracoMediaUrl } from "./getUmbracoMediaUrl"

interface PropertyValue {
  value?: string
  richText?: string
  blocks?: any[] // Adjust the type as per your data structure if needed
  mediaItems?: { url: string }[]
  links?: []
  dateTime?: string
  contentList?: any
}

interface DataProperty {
  propertyName: string | null
  propertyValue: PropertyValue | null
}

interface Data {
  properties?: DataProperty[]
}

export function extractImage(
  data: Data | any,
  propertyName: string
): {
  url: string
  altText: string
} {
  if (!data || !propertyName) {
    return { url: "", altText: "" }
  }

  const property = data.properties?.find(
    (prop: DataProperty) => prop.propertyName === propertyName
  )

  //if url is with http:// convert to https://
  if (property?.propertyValue?.mediaItems?.[0]?.url) {
    property.propertyValue.mediaItems[0].url =
      property.propertyValue.mediaItems[0].url.replace("http://", "https://")
  }

  // Return the first image URL or an empty string if not found
  return property?.propertyValue?.mediaItems?.[0]
    ? {
        url: getUmbracoMediaUrl(property?.propertyValue?.mediaItems?.[0]?.url),
        altText: property?.propertyValue?.mediaItems?.[0]?.altText,
      }
    : { url: "", altText: "" }
}
