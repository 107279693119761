export const GlobalStyle = `

* {
  box-sizing: border-box;
  font-family: var(--rag) !important;
  :focus-visible {
    border: 2px dashed white;
    outline-offset: 2px;
    outline: 3px dashed #10059f;
  }

}


:root {
  direction: rtl;
  font-family: var(--rag) !important;
  direction: rtl;


}


body {
  font-family: var(--rag) !important;
}

html {
  overscroll-behavior-x: none;
}

  .swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: calc(100% - 40px);
  }

  .swiper-slide img {
    display: block;
    width: 100%;
  }
  .swiper-button-disabled {
    opacity:0.3;
  }
  .MuiPopover-root{
    z-index:1000000 !important;
  }


`
