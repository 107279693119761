"use client"

import styled from "@emotion/styled"
import { Button } from "@mui/material"
import { useTranslations } from "next-intl"
import { Dispatch, SetStateAction } from "react"
import NewText from "./NewText/NewText"
import { FormFailIcon, FormSuccessIcon } from "./icons"
import { sharedColors as colors } from "./theme/colors"

const StyledForm = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  min-height: 400px;
  text-align: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    min-height: 400px;
  }
`
const StyledText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledButton = styled(Button)`
  display: flex;
  width: 153px;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 200px;
  border: 2px solid black;
  background: white;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  :hover {
    text-decoration: none;
  }
`

type Props = {
  state: "success" | "fail"
  setIsSubmitted: Dispatch<SetStateAction<boolean>>
  messages: any
  type: "contactUs" | "project" | "career"
  onClose?: any
}
function SuccessFailState({ state, setIsSubmitted, messages, type, onClose = () => {} }: Props) {
  const t = useTranslations("SuccessFailBox")
  const getMessage = () => {
    if (state === "success") {
      switch (type) {
        case "career":
          return t("successCareer")
        case "contactUs":
          return t("successContactUs")
        case "project":
          return t("successProject")
        default:
          return ""
      }
    } else {
      switch (type) {
        case "career":
          return t("errorCareer")
        case "contactUs":
          return t("errorContactUs")
        case "project":
          return t("errorProject")
        default:
          return ""
      }
    }
  }
  return (
    <StyledForm>
      {state === "success" ? <FormSuccessIcon /> : <FormFailIcon />}
      <StyledText>
        {/* <NewText component={"h3"} desktopFontSize={32} color={colors.black}>
          {state === "success"
            ? type === "rent"
              ? messages.successTitleRent
              : messages.sucessTitle
            : "פנייתך לא נשלחה "}
        </NewText> */}
        {/* <NewText component={"p"} desktopFontSize={18} color={colors.black} maxWidth={"400px"}>
          {state === "success"
            ? type === "rent"
              ? messages.successTextRent
              : messages.sucessText
            : `לורם איפסום דולר לורם איפסום דולר
          לורם איפסום דולר לורם איפסום`}
        </NewText> */}
        <NewText component={"p"} desktopFontSize={18} color={colors.black} maxWidth={"400px"}>
          {getMessage()}
        </NewText>
      </StyledText>
      {state === "fail" ? (
        <StyledButton onClick={() => setIsSubmitted(false)}>ניסיון נוסף</StyledButton>
      ) : (
        <StyledButton onClick={() => onClose()}>{messages.close}</StyledButton>
      )}
    </StyledForm>
  )
}

export default SuccessFailState
