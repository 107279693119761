"use client"

import styled from "@emotion/styled"
import { useInView } from "react-intersection-observer"
import { extractProperty } from "utils"
import {
  Container,
  NewText,
  styleObjectToCss,
  useInViewConfig,
  useTransitionUpStyles,
} from "./"
import { sharedColors } from "./theme/colors"

const StyledContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 40px;
    margin-bottom: 40px;
  }
`
type Props = {
  data: any
}

function MainTitle({ data }: Props) {
  const { ref, inView } = useInView(useInViewConfig)

  const mainTitle = extractProperty(data, "mainTitle")
  const titleTransitionStyles = useTransitionUpStyles(inView)

  return (
    <StyledContainer ref={ref}>
      <Container>
        <NewText
          component="h1"
          desktopFontSize={40}
          desktopLineHeight="36px"
          mobileFontSize={32}
          mobileLineHeight="36px"
          color={sharedColors.black}
          cssString={styleObjectToCss(titleTransitionStyles)}
        >
          {mainTitle}
        </NewText>
      </Container>
    </StyledContainer>
  )
}

export default MainTitle
