import * as React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  position: "top" | "bottom"
}

const CircleEllipse = (props: Props) => {
  if (props.position === "bottom") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={101}
        height={160}
        fill="none"
        {...props}
      >
        <circle cx={1} cy={100} r={100} fill="url(#a)" fillOpacity={0.24} />
        <defs>
          <linearGradient
            id="a"
            x1={1}
            x2={1}
            y1={0}
            y2={200}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#fff" />
            <stop offset={1} stopColor="#fff" stopOpacity={0} />
          </linearGradient>
        </defs>
      </svg>
    )
  }
  if (props.position === "top") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={125}
        height={184}
        fill="none"
        {...props}
      >
        <circle cx={100} cy={84} r={100} fill="url(#a)" fillOpacity={0.24} />
        <defs>
          <linearGradient
            id="a"
            x1={100}
            x2={100}
            y1={-16}
            y2={184}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#fff" />
            <stop offset={1} stopColor="#fff" stopOpacity={0} />
          </linearGradient>
        </defs>
      </svg>
    )
  }
}
export default CircleEllipse
