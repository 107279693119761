import { SVGProps } from "react"
const SubmitSearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <rect width={40} height={40} fill="#FCBB00" rx={20} />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M18.667 27 12 20m0 0 6.667-7M12 20h16"
    />
  </svg>
)
export default SubmitSearchIcon
