"use client"
import styled from "@emotion/styled"
import { useInView } from "react-intersection-observer"
import "swiper/css"
import { extractProperty } from "utils"
import Container from "../Container"
import NewText from "../NewText/NewText"
import { useIsDesktop } from "../common/bit/MediaQueries"
import { useTransitionUpStyles } from "../transitions/useTransitionUpStyles"
import {
  delayTime,
  styleObjectToCss,
  useInViewConfig,
} from "../transitions/utils"

type Props = {
  data: any
  maxWidth?: string
  underline?: boolean
  marginBottom?: string
  hasBanner: boolean
}

const StyledUnderLine = styled.div`
  height: 2px;
  width: 100%;
  background-color: #f7f7f7;
  margin-top: 40px;
`
const StyledWrapper = styled.div`
  background: url("/images/heroBG.png"), #2768a3;
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-size: contain;
  margin-bottom: 32px;
  margin-left: 16px;
  margin-right: 16px;
  border-radius: 8px;
  > div > div {
    background: transparent;
    display: inline-block;
  }
  div,
  h1,
  p {
    margin-left: auto;
    margin-right: auto;
    color: white;
    text-align: center;
  }
  h1 {
    font-size: 40px;
    max-width: 675px;
  }
  div,
  p {
    font-size: 24px;
  }
  p {
    max-width: 675px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    min-width: 100vw;
    margin-bottom: 44px;
    margin-left: 0;
    margin-right: 0;
    background-position-y: center;
    border-radius: unset;
    h1 {
      font-size: 48px;
    }
    div {
      font-size: 18px;
    }
  }
`

export const ContentText: React.FC<Props> = ({
  data,
  maxWidth,
  underline = false,
  marginBottom,
  hasBanner,
}) => {
  const title = extractProperty(data, "conetntTitle")
  const innerText = extractProperty(data, "innerText")
  const isDesktop = useIsDesktop()
  const { ref, inView } = useInView(useInViewConfig)

  const titleTransitionStyles = useTransitionUpStyles(inView)
  const descriptionTransitionStyles = useTransitionUpStyles(inView, delayTime)
  if (!hasBanner) {
    return (
      <StyledWrapper>
        <Container
          style={{
            paddingBottom: isDesktop
              ? marginBottom
                ? marginBottom
                : "40px"
              : marginBottom
              ? marginBottom
              : "32px",
            paddingTop: "32px",
          }}
          containerRef={ref}
        >
          <NewText
            desktopFontSize={40}
            desktopFontWeight="400"
            desktopLineHeight="110%"
            mobileFontSize={32}
            mobileFontWeight="400"
            mobileLineHeight={"112.5%"}
            component="h1"
            color={"black"}
            cssString={styleObjectToCss(titleTransitionStyles)}
          >
            {title}
          </NewText>
          <NewText
            desktopFontSize={24}
            desktopFontWeight="400"
            desktopLineHeight="34px"
            mobileFontSize={18}
            mobileFontWeight="400"
            mobileLineHeight={"26px"}
            component="div"
            color={"black"}
            dangerouslySetInnerHTML={{ __html: innerText }}
            style={{ marginBlockStart: 16, padding: 0 }}
            maxWidth={maxWidth || "800px"}
            cssString={styleObjectToCss(descriptionTransitionStyles)}
          />
          {underline && <StyledUnderLine />}
        </Container>
      </StyledWrapper>
    )
  }
  return (
    <Container
      style={{
        paddingBottom: isDesktop
          ? marginBottom
            ? marginBottom
            : "40px"
          : marginBottom
          ? marginBottom
          : "32px",
        paddingTop: "32px",
      }}
      containerRef={ref}
    >
      <NewText
        desktopFontSize={40}
        desktopFontWeight="400"
        desktopLineHeight="110%"
        mobileFontSize={32}
        mobileFontWeight="400"
        mobileLineHeight={"112.5%"}
        component="h1"
        color={"black"}
        cssString={styleObjectToCss(titleTransitionStyles)}
      >
        {title}
      </NewText>
      <NewText
        desktopFontSize={24}
        desktopFontWeight="400"
        desktopLineHeight="34px"
        mobileFontSize={24}
        mobileFontWeight="400"
        mobileLineHeight={"26px"}
        component="div"
        color={"black"}
        dangerouslySetInnerHTML={{ __html: innerText }}
        style={{ marginBlockStart: 16, padding: 0 }}
        maxWidth={maxWidth || "800px"}
        cssString={styleObjectToCss(descriptionTransitionStyles)}
      />
      {underline && <StyledUnderLine />}
    </Container>
  )
}
export default ContentText
