const FormHelperValidIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <path
      d="M13.3327 4.5L5.99935 11.8333L2.66602 8.5"
      stroke="#009262"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default FormHelperValidIcon
