import { SVGProps } from "react"
const ContactUsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#292D32"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M14.168 17.083H5.835c-2.5 0-4.167-1.25-4.167-4.166V7.083c0-2.916 1.667-4.166 4.167-4.166h8.333c2.5 0 4.167 1.25 4.167 4.166v5.834c0 2.916-1.667 4.166-4.167 4.166Z"
    />
    <path
      stroke="#292D32"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m14.165 7.5-2.608 2.083c-.858.684-2.267.684-3.125 0L5.832 7.5"
    />
  </svg>
)
export default ContactUsIcon
