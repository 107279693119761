"use client"

import styled from "@emotion/styled"

import { GetFootersQuery } from "apollo"
import { extractImage } from "utils"
import { extractProperty } from "utils/extractProperty"
import Container from "../../Container"
import { Desktop, Mobile } from "../../common/bit/MediaQueries"
import { MintIcon } from "../../icons"
import Copyright from "./Copyright"
import Env from "./Env"
import FooterLinksDesktop from "./FooterLinksDesktop"
import FooterLinksMobile from "./FooterLinksMobile"
import GridDesktop from "./GridDesktop"
import GridMobile from "./GridMobile"
import SocialsDesktop from "./SocialsDesktop"
import SocialsMobile from "./SocialsMobile"

const StyledSocialContainer = styled.div`
  display: flex;

  ${(props) => props.theme.breakpoints.up("md")} {
    justify-content: flex-end;
  }
`
const StyledFooter = styled.footer`
  width: 100%;
  background-color: ${(props) => props.theme.colors.lightGrey};
`

const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  padding-bottom: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 60px 0px 40px 0px;
  }
`

const StyledLast = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  ${(props) => props.theme.breakpoints.down("sm")} {
    > div {
      width: 100%;
    }
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    flex-direction: row;
    padding-bottom: 24px;
    border-bottom: 1px solid white;
    align-items: center;
  }
`

const StyledBottomDiv = styled.div`
  background-color: #2768a3;
  padding-top: 24px;
  padding-bottom: 24px;
`

const StyledMobileWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 16px;
  svg {
    margin-top: 16px;
  }
`

interface Props {
  data: GetFootersQuery
  companyName: string
}

function Footer({ data }: Props) {
  const desktopFooterData = data?.footer_desktop
  const mobileFooterData = data?.footer_mobile

  const footerBottomLinksDesktop = extractProperty(desktopFooterData, "bottomLinks")
  const footerBottomLinksMobile = extractProperty(mobileFooterData, "bottomLinks")

  const desktopUrls = extractProperty(footerBottomLinksDesktop[0], "links") || []
  const mobileUrls = extractProperty(footerBottomLinksMobile[0], "links") || []

  const socialLinksDesktop = extractProperty(desktopFooterData, "socialLinks")
  const socialLinksMobile = extractProperty(mobileFooterData, "socialLinks")
  const desktopSocialUrls = socialLinksDesktop?.[0]?.properties?.[0]?.propertyValue?.blocks || []
  const mobileSocialUrls = socialLinksMobile?.[0]?.properties?.[0]?.propertyValue?.blocks || []

  const desktopItems = extractProperty(desktopFooterData, "lists")
  const mobileItems = extractProperty(mobileFooterData, "lists")

  const englishDesktop = extractProperty(footerBottomLinksDesktop[0], "english")
  const text = extractProperty(footerBottomLinksDesktop[0], "text")

  const contactInfo = extractProperty(desktopFooterData, "contactInfo")

  const parentSiteLogoDesktop = extractImage(desktopFooterData, "parentSiteLogo")

  const parentLinkDesktop = extractProperty(desktopFooterData, "parentLink")

  const parentSiteLogoMobile = extractImage(mobileFooterData, "parentSiteLogo")

  const parentLinkMobile = extractProperty(mobileFooterData, "parentLink")

  return (
    <StyledFooter role="contentinfo">
      <Container>
        <StyledList>
          {/* <Logo data={data} companyName={companyName} /> */}

          {/* LIST OF MAIN FOOTER ITEMS */}
          <GridDesktop desktopItems={desktopItems} contactInfo={contactInfo} />
          <GridMobile mobileItems={mobileItems} />
          {/* LIST OF MAIN FOOTER ITEMS */}
        </StyledList>
      </Container>
      <StyledBottomDiv>
        <Container>
          {/* LIST OF SOCIAL LINKS */}
          <StyledLast>
            {mobileSocialUrls.length > 0 && (
              <SocialsMobile
                mobileSocialUrls={mobileSocialUrls}
                parentSiteLogoMobile={parentSiteLogoMobile}
                parentLinkMobile={parentLinkMobile}
              />
            )}
            <FooterLinksDesktop
              desktopUrls={desktopUrls}
              englishDesktop={englishDesktop}
              parentSiteLogoDesktop={parentSiteLogoDesktop}
              parentLinkDesktop={parentLinkDesktop}
            />
            <FooterLinksMobile mobileUrls={mobileUrls} englishDesktop={englishDesktop} />

            {desktopSocialUrls.length > 0 && (
              <StyledSocialContainer>
                {desktopSocialUrls.length > 0 && (
                  <SocialsDesktop desktopSocialUrls={desktopSocialUrls} />
                )}
              </StyledSocialContainer>
            )}
            {/* LIST OF SOCIAL LINKS */}

            {/* FOOTER EXTRA LINKS */}

            {/* FOOTER EXTRA LINKS */}
          </StyledLast>
          <Desktop>
            <Copyright englishDesktop={englishDesktop} text={text} />
          </Desktop>
          <Mobile>
            <StyledMobileWrapper>
              <Copyright englishDesktop={englishDesktop} text={text} />

              <MintIcon />
            </StyledMobileWrapper>
          </Mobile>
          <Env />
        </Container>
      </StyledBottomDiv>
    </StyledFooter>
  )
}

export default Footer
