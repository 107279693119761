import { IntersectionOptions } from "react-intersection-observer"

export const transitionTime = 0.8
export const delayTime = 0.2
export const transitionEase = "ease"

export const transitionUpValue = "50px"

export const useInViewConfig: IntersectionOptions = {
  triggerOnce: true,
  threshold: 0.3,
  rootMargin: "300px 0px 0px 0px",
}
export const styleObjectToCss = (styles: Record<string, any>): string => {
  return Object.entries(styles)
    .map(([key, value]) => {
      const cssKey = key.replace(/([A-Z])/g, "-$1").toLowerCase() // converts camelCase to kebab-case
      return `${cssKey}: ${value};`
    })
    .join(" ")
}
