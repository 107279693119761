import { SVGProps } from "react"
const ErrorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.53984 7.17433C5.48541 3.72478 6.4582 2 7.9987 2C9.5392 2 10.512 3.72477 12.4576 7.17432L12.7 7.60418C14.3168 10.4707 15.1251 11.904 14.3945 12.952C13.6639 14 11.8563 14 8.24114 14H7.75626C4.14107 14 2.33348 14 1.60287 12.952C0.872259 11.904 1.68064 10.4707 3.2974 7.60418L3.53984 7.17433Z"
      stroke="#D10040"
      strokeWidth="1.5"
    />
    <path
      d="M8 5.33301V8.66634"
      stroke="#D10040"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <ellipse
      cx="7.9987"
      cy="10.6667"
      rx="0.666667"
      ry="0.666667"
      fill="#D10040"
    />
  </svg>
)
export default ErrorIcon
