import { List } from "@mui/material"
import { extractProperty } from "utils"
import FooterList from "../../FooterList"
import { Mobile } from "../../common/bit/MediaQueries"
import { sharedColors } from "../../theme/colors"

type Props = {
  mobileItems: any
}

function GridMobile({ mobileItems }: Props) {
  return (
    <Mobile>
      <List
        sx={{
          width: "100%",
          borderBottom: `1px solid rgba(111, 111, 111, 0.50);`,
          padding: 0,
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {mobileItems.map((item: any, index: number) => {
          const title = extractProperty(item, "title")

          const childrenUnsorted = extractProperty(item, "urls")
          const childrenSorted = childrenUnsorted.map((it: any) => {
            const link = extractProperty(it, "link")
            const linkTitle = extractProperty(it, "linkTitle")

            return {
              title: linkTitle,
              url: link?.[0]?.url,
            }
          })

          return (
            <FooterList
              color={sharedColors.black}
              key={index.toString()}
              title={title}
            >
              {childrenSorted}
            </FooterList>
          )
        })}
      </List>
    </Mobile>
  )
}

export default GridMobile
