import { SVGProps } from "react"

const Mute = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_362_11266)">
        <rect x="8" y="4" width="32" height="32" rx="16" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.9011 14.7115C25.5181 14.1785 26.4759 14.6168 26.4759 15.4321V17.1986L21.0872 22.5872H19.8099C19.0736 22.5872 18.4767 21.9903 18.4767 21.254V18.7454C18.4767 18.0091 19.0736 17.4122 19.8099 17.4122L20.9951 17.4122C21.498 17.4122 21.9841 17.2313 22.3647 16.9025L24.9011 14.7115ZM21.5605 23.73H21.5621V23.7284L30.4986 14.7919C30.7217 14.5688 30.7217 14.207 30.4986 13.9839C30.2754 13.7608 29.9137 13.7608 29.6905 13.9839L27.6186 16.0558V15.4321C27.6186 13.6384 25.5114 12.6741 24.154 13.8467L21.6176 16.0378C21.4447 16.1872 21.2237 16.2694 20.9951 16.2694L19.8099 16.2694C18.4425 16.2694 17.334 17.378 17.334 18.7454V21.254C17.334 22.6214 18.4425 23.73 19.8099 23.73H19.9445L18.2632 25.4113C18.04 25.6344 18.04 25.9962 18.2632 26.2193C18.4863 26.4424 18.8481 26.4424 19.0712 26.2193L21.5605 23.73ZM27.6186 20.4825C27.6186 20.167 27.3628 19.9112 27.0472 19.9112C26.7317 19.9112 26.4759 20.167 26.4759 20.4825V21.6253V24.5673C26.4759 25.3826 25.5181 25.8209 24.9011 25.2879L24.6782 25.0954L24.6703 25.0885L24.6621 25.082L23.5955 24.2268C23.3494 24.0294 22.9898 24.0689 22.7924 24.3151C22.595 24.5613 22.6345 24.9209 22.8807 25.1183L23.9391 25.967L24.154 26.1527C25.5114 27.3252 27.6186 26.361 27.6186 24.5673V21.6253V20.4825Z"
          fill="black"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_362_11266"
          x="0"
          y="0"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_362_11266"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_362_11266"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default Mute
