"use client"

import { RefObject, useEffect } from "react"

export const useOutsideClick = (
  refs: RefObject<HTMLElement>[],
  callback: () => void
): void => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      // Start with the target element
      let element = event.target as Node

      // Check if any parent element has the MuiMenu-paper class
      let isInsideMuiMenu = false
      while (element && element instanceof HTMLElement) {
        if (
          element.classList.contains("MuiMenu-paper") ||
          element.classList.contains("MuiBackdrop-root")
        ) {
          isInsideMuiMenu = true
          break
        }
        element = element.parentNode as Node
      }

      // Only invoke the callback if click is outside refs and not within MuiMenu-paper
      if (
        !isInsideMuiMenu &&
        refs.every(
          (ref) => ref.current && !ref.current.contains(event.target as Node)
        )
      ) {
        callback()
      }
    }

    document?.addEventListener("mousedown", handleClickOutside)
    return () => {
      document?.removeEventListener("mousedown", handleClickOutside)
    }
  }, [refs, callback])
}
