"use client"
import styled from "@emotion/styled"

import { Desktop, Mobile } from "../common/bit/MediaQueries"
import NewText from "../NewText/NewText"
import { sharedColors } from "../theme/colors"

import MIUITimeline from "@mui/lab/Timeline"
import TimelineConnector from "@mui/lab/TimelineConnector"
import TimelineContent from "@mui/lab/TimelineContent"
import TimelineDot from "@mui/lab/TimelineDot"
import TimelineItem from "@mui/lab/TimelineItem"
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent"
import TimelineSeparator from "@mui/lab/TimelineSeparator"
import Image from "next/image"

import { Button, IconButton } from "@mui/material"
import Link from "next/link"
import { useRouter } from "next/navigation"
import { useState } from "react"
import { useInView } from "react-intersection-observer"
import "swiper/css"
import { Swiper, SwiperSlide } from "swiper/react"
import { getNonUmbracoUrl, getUmbracoMediaUrl, pageStripClick } from "utils"
import {
  transitionDelayTime,
  transitionTime,
  TransitionUp,
  useInViewConfig,
} from ".."
import { LeftArrow, RightArrow } from "../icons"
import { transitionEase } from "../transitions/utils"

type Props = {
  data: any
  readMore: { text: string; url: string }
  locale: string
  conetntTitle?: string
}

const StyledDesktopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`
const StyledBackDropTextContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  padding: 10px;
  opacity: 0;
  z-index: 2;
  text-align: center;
  transition: all 0.8s ${transitionEase};
  &:hover {
    opacity: 1;
  }
`

const StyledDesktopTimeLineItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  &:focus-visible {
    ${StyledBackDropTextContainer} {
      opacity: 1;
    }
  }
`
const StyledBottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: -24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 40px;
  }
`

const StyledPaginatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const StyledBulletsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 4px;
`

const StyledBullet = styled.div<{ active: boolean }>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 0 2px;
  cursor: pointer;
  border: ${(props) =>
    props.active ? "1px solid #0915BC" : "1px solid black"};
  background-color: ${(props) => (props.active ? "#0915BC" : "none")};
`
const StyledImageContainer = styled.div`
  position: relative;
  overflow: hidden;
`

const StyledBlacnkImageContainer = styled.div`
  width: 143px;
  height: 143px;
`

export const TimeLine: React.FC<Props> = ({
  data,
  readMore = { text: "", url: "" },
  locale,
  conetntTitle,
}) => {
  const { text, url } = readMore

  const [swiperRef, setSwiperRef] = useState<any>(null)
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const { ref, inView } = useInView(useInViewConfig)
  const { ref: mobileRef, inView: mobileInView } = useInView(useInViewConfig)

  const getGroupedData = () => {
    const list = data()
    const chunkSize = 6
    const numChunks = Math.ceil(list.length / chunkSize)
    const chunks = []
    for (let i = 0; i < numChunks; i++) {
      const start = i * chunkSize
      const end = start + chunkSize
      const chunk = list.slice(start, end)

      while (chunk.length < chunkSize) {
        chunk.push("empty")
      }
      chunks.push(chunk)
    }

    return chunks
  }
  const router = useRouter()
  const navigateToHistoryPage = (id: string) => {
    router.push(locale === "he" ? `/history#${id}` : `/en/history#${id}`)
  }

  return (
    <>
      <Mobile>
        <MIUITimeline
          position="alternate"
          sx={{
            maxWidth: "358px",
            margin: "62px auto",
            ":nth-of-type(2n)": { flexDirection: "row" },
            marginBlockEnd: 0,
          }}
          ref={mobileRef}
        >
          {data()
            .reverse()
            .map((item: any, index: number) => (
              <TimelineItem
                position={index % 2 === 0 ? "alternate" : "alternate-reverse"}
                key={index}
              >
                <TimelineOppositeContent color="text.secondary">
                  {index % 2 === 0 ? (
                    <>
                      <TransitionUp
                        $inView={mobileInView}
                        $delay={transitionDelayTime * 0.5 * index}
                      >
                        <NewText
                          desktopFontSize={20}
                          desktopFontWeight="400"
                          desktopLineHeight="28px"
                          mobileFontSize={20}
                          mobileFontWeight="600"
                          mobileLineHeight={"28px"}
                          component="p"
                          color={sharedColors.black}
                          maxWidth={"580px"}
                          marginTop={"-10px"}
                          textAlign="left"
                        >
                          {item.title}
                        </NewText>
                        <NewText
                          desktopFontSize={20}
                          desktopFontWeight="400"
                          desktopLineHeight="26px"
                          mobileFontSize={18}
                          mobileFontWeight="400"
                          mobileLineHeight={"26px"}
                          component="p"
                          color={sharedColors.black}
                          maxWidth={"580px"}
                          marginTop={"8px"}
                          textAlign="left"
                        >
                          {item.description}
                        </NewText>
                      </TransitionUp>
                    </>
                  ) : (
                    <TransitionUp
                      $inView={mobileInView}
                      $delay={transitionDelayTime * 0.5 * index + 0.1}
                    >
                      <Image
                        src={getUmbracoMediaUrl(item.image)}
                        width={100}
                        height={100}
                        alt="timeline item"
                        style={{
                          borderRadius: "8px",
                          marginBlockStart: "-32px",
                          marginBlockEnd: "62px",
                        }}
                      />
                    </TransitionUp>
                  )}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    sx={{
                      backgroundColor: sharedColors.propBlue,
                      boxShadow: "none",
                      margin: "0 auto",
                      width: "8px",
                      height: "8px",
                      borderWidth: 0,
                    }}
                  />
                  {index !== data().length - 1 && (
                    <TimelineConnector
                      sx={{
                        backgroundColor: sharedColors.propBlue,
                        boxShadow: "none",
                        width: "1px",
                      }}
                    />
                  )}
                </TimelineSeparator>
                <TimelineContent>
                  {index % 2 === 0 ? (
                    <TransitionUp
                      $inView={mobileInView}
                      $delay={transitionDelayTime * 0.5 * index + 0.1}
                    >
                      <Image
                        src={getUmbracoMediaUrl(item.image)}
                        width={100}
                        height={100}
                        alt="timeline item"
                        style={{
                          borderRadius: "8px",
                          marginBlockStart: "-32px",
                          marginBlockEnd: "62px",
                        }}
                      />
                    </TransitionUp>
                  ) : (
                    <>
                      <TransitionUp
                        $inView={mobileInView}
                        $delay={transitionDelayTime * 0.5 * index}
                      >
                        <NewText
                          desktopFontSize={20}
                          desktopFontWeight="400"
                          desktopLineHeight="28px"
                          mobileFontSize={20}
                          mobileFontWeight="600"
                          mobileLineHeight={"28px"}
                          component="p"
                          color={sharedColors.black}
                          maxWidth={"580px"}
                          marginTop={"-10px"}
                          textAlign="left"
                        >
                          {item.title}
                        </NewText>
                        <NewText
                          desktopFontSize={20}
                          desktopFontWeight="400"
                          desktopLineHeight="26px"
                          mobileFontSize={18}
                          mobileFontWeight="400"
                          mobileLineHeight={"26px"}
                          component="p"
                          color={sharedColors.black}
                          maxWidth={"580px"}
                          marginTop={"8px"}
                          textAlign="left"
                        >
                          {item.description}
                        </NewText>
                      </TransitionUp>
                    </>
                  )}
                </TimelineContent>
              </TimelineItem>
            ))}
        </MIUITimeline>
        <StyledBottomContainer>
          <Button
            variant="outlined"
            size="small"
            sx={{ marginInlineStart: "auto", fontSize: "20px" }}
            LinkComponent={Link}
            href={getNonUmbracoUrl(url) || ""}
          >
            {text}
          </Button>
        </StyledBottomContainer>
      </Mobile>
      <Desktop>
        <StyledDesktopContainer ref={ref}>
          <Swiper
            onSwiper={setSwiperRef}
            slidesPerView={1}
            spaceBetween={36}
            onSlideChange={(swiper) => {
              setActiveIndex(swiper.activeIndex)
            }}
            style={{
              padding: 0,
            }}
          >
            {getGroupedData().map((list: any, index: number) => (
              <SwiperSlide
                virtualIndex={index}
                key={index.toString()}
                style={{
                  display: "flex",
                  boxSizing: "content-box",
                  width: "100%",
                  gap: "36px",
                  justifyContent: "space-between",
                }}
              >
                {list.map((i: any, k: number) => {
                  const transitionDelay = transitionDelayTime * 0.5 * k
                  if (i !== "empty") {
                    return (
                      <TransitionUp
                        $inView={inView}
                        $delay={transitionDelay}
                        key={k.toString()}
                      >
                        <StyledDesktopTimeLineItemContainer
                          onClick={() => {
                            navigateToHistoryPage(i.title)
                          }}
                          role="link"
                          tabIndex={0}
                        >
                          <StyledImageContainer>
                            <Image
                              src={getUmbracoMediaUrl(i.image)}
                              width={143}
                              height={143}
                              alt="timeline item"
                              style={{
                                borderRadius: "8px",
                                zIndex: 2,
                                position: "relative",
                              }}
                            />
                            <StyledBackDropTextContainer>
                              <NewText
                                desktopFontSize={16}
                                desktopFontWeight="400"
                                desktopLineHeight="100%"
                                mobileFontSize={14}
                                mobileFontWeight="400"
                                mobileLineHeight={"100%"}
                                component="p"
                                color={sharedColors.white}
                                maxWidth={"580px"}
                                marginTop={"8px"}
                              >
                                {i.description}
                              </NewText>
                            </StyledBackDropTextContainer>
                          </StyledImageContainer>
                          <TimelineConnector
                            sx={{
                              backgroundColor: sharedColors.propBlue,
                              boxShadow: "none",
                              width: "1px",
                              height: "60px",
                              zIndex: 1,
                              transition: `all 0.3s ${transitionEase}`,
                              transitionDelay: `${
                                transitionTime + transitionDelay
                              }s`,
                              opacity: inView ? 1 : 0,
                              transform: inView
                                ? "translateY(0)"
                                : "translateY(-70px)",
                            }}
                          />
                          <TimelineDot
                            sx={{
                              backgroundColor: sharedColors.propBlue,
                              boxShadow: "none",
                              margin: "0 auto",
                              width: "8px",
                              height: "8px",
                              borderWidth: 0,
                              transition: `all 0.3s ${transitionEase}`,
                              transitionDelay: `${
                                transitionTime + transitionDelay + 0.3
                              }s`,
                              opacity: inView ? 1 : 0,
                              transform: inView ? "scale(1)" : "scale(0)",
                            }}
                          />
                          <TransitionUp
                            $inView={inView}
                            $delay={transitionTime + transitionDelay + 0.5}
                          >
                            <NewText
                              desktopFontSize={20}
                              desktopFontWeight="400"
                              desktopLineHeight="26px"
                              mobileFontSize={16}
                              mobileFontWeight="400"
                              mobileLineHeight={"125%"}
                              component="p"
                              color={sharedColors.black}
                              maxWidth={"580px"}
                              marginTop={"8px"}
                            >
                              {i.title}
                            </NewText>
                          </TransitionUp>
                        </StyledDesktopTimeLineItemContainer>
                      </TransitionUp>
                    )
                  } else {
                    return (
                      <StyledDesktopTimeLineItemContainer key={k.toString()}>
                        <StyledBlacnkImageContainer />
                      </StyledDesktopTimeLineItemContainer>
                    )
                  }
                })}
              </SwiperSlide>
            ))}
          </Swiper>
        </StyledDesktopContainer>
        <StyledBottomContainer>
          <StyledPaginatorContainer>
            <IconButton
              onClick={() => {
                swiperRef?.slidePrev()
              }}
            >
              {locale === "en" ? <LeftArrow /> : <RightArrow />}
            </IconButton>
            <StyledBulletsContainer>
              {getGroupedData().map((_: any, index: number) => (
                <StyledBullet
                  key={index.toString()}
                  onClick={() => {
                    swiperRef?.slideTo(index)
                  }}
                  active={index === activeIndex}
                />
              ))}
            </StyledBulletsContainer>
            <IconButton
              onClick={() => {
                swiperRef?.slideNext()
              }}
            >
              {locale === "en" ? <RightArrow /> : <LeftArrow />}
            </IconButton>
          </StyledPaginatorContainer>

          <Button
            variant="outlined"
            size="large"
            sx={{ marginInlineStart: "auto", fontSize: "20px" }}
            LinkComponent={Link}
            href={getNonUmbracoUrl(url) || ""}
            onClick={() => {
              pageStripClick(text, conetntTitle)
            }}
          >
            {text}
          </Button>
        </StyledBottomContainer>
      </Desktop>
    </>
  )
}

export default TimeLine
