"use client"
import SearchInput from "../SearchInput/SearchInput"

type Props = {
  title: string
  subtitle: string
  placeholder: string
}

export const PositionedSearch: React.FC<Props> = ({ placeholder }) => {
  return <SearchInput placeholder={placeholder} />
}

export default PositionedSearch
