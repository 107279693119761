"use client"
import styled from "@emotion/styled"

import NewText from "../NewText/NewText"
import { sharedColors } from "../theme/colors"

const StyledTextWrapper = styled.div<{
  $marginTop?: number
  $marginLeft?: number
}>`
  display: flex;
  position: relative;
  margin-top: ${(p) => `${p.$marginTop}px`};
  margin-left: ${(p) => `${p.$marginLeft}px`};
  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    h2 {
      font-size: 42px;
    }
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    h2 {
      font-size: 64px;
    }
  }
`

const StyledTitleLine = styled.div`
  width: 28px;
  height: 2px;
  background: ${sharedColors.black};
  ${(props) => props.theme.breakpoints.up("md")} {
    position: absolute;
    width: 40px;
    height: 4px;
    left: -52px;
    top: 30px;
  }
`

type TitleWithLineProps = {
  titleFontSize?: number
  titleColor?: string
  title: string
  marginTop?: number
  marginLeft?: number
  mobileTitleFontSize?: number
}

function TitleWithLine({
  titleFontSize,
  mobileTitleFontSize,
  titleColor,
  title,
  marginTop = 0,
  marginLeft = 0,
}: TitleWithLineProps) {
  return (
    <StyledTextWrapper $marginTop={marginTop} $marginLeft={marginLeft}>
      <StyledTitleLine />
      <NewText
        mobileFontSize={
          mobileTitleFontSize
            ? mobileTitleFontSize
            : titleFontSize
            ? titleFontSize
            : 36
        }
        desktopFontSize={titleFontSize ? titleFontSize : 64}
        component="h2"
        desktopLineHeight="128%"
        desktopFontWeight="500"
        color={titleColor ? titleColor : sharedColors.blue}
      >
        {title}
      </NewText>
    </StyledTextWrapper>
  )
}

export default TitleWithLine
