const StopSign = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M12.6511 2.5H10.2775C9.98616 2.5 9.75 2.70932 9.75 2.96753V12.3182C9.75 12.5764 9.98616 12.7857 10.2775 12.7857H12.6511C12.9424 12.7857 13.1786 12.5764 13.1786 12.3182V2.96753C13.1786 2.70932 12.9424 2.5 12.6511 2.5Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.79368 2.5H3.42005C3.12874 2.5 2.89258 2.70932 2.89258 2.96753V12.3182C2.89258 12.5764 3.12874 12.7857 3.42005 12.7857H5.79368C6.08499 12.7857 6.32115 12.5764 6.32115 12.3182V2.96753C6.32115 2.70932 6.08499 2.5 5.79368 2.5Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default StopSign
