"use client"
import styled from "@emotion/styled"

import ButtonWithArrow from "../ButtonWithArrow"
import SectionTitle from "../SectionTitle"
import { Desktop } from "../common/bit/MediaQueries"
import { sharedColors } from "../theme/colors"

const StyledContainer = styled.div`
  padding: 32px 16px 32px 16px;
  max-width: 1280px;
  margin: 0 auto;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 80px 120px 80px 120px;
  }
`

const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ProjectGallery: React.FC = () => {
  return (
    <StyledContainer>
      <StyledTitleContainer>
        <SectionTitle
          title={"יוצרים מציאות חדשה"}
          accentColor={sharedColors.propBlue}
        />
        <Desktop>
          <ButtonWithArrow title="לפרויקטים נוספים" />
        </Desktop>
      </StyledTitleContainer>
      {/* <ProjectsSlider data={dummyData} /> */}
    </StyledContainer>
  )
}

export default ProjectGallery
