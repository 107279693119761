const FaqIcon = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_13526_179917)">
        <path
          d="M57.2851 20.0814C56.9195 20.0814 56.5542 19.9425 56.2746 19.6641C55.7139 19.106 55.712 18.1991 56.27 17.6386L57.9151 15.9862C58.4732 15.4257 59.3801 15.4236 59.9407 15.9817C60.5014 16.5397 60.5034 17.4466 59.9453 18.0072L58.3001 19.6595C58.0203 19.9408 57.6527 20.0814 57.2851 20.0814Z"
          fill="#2768A3"
        />
        <path
          d="M49.668 16.9122C48.877 16.9122 48.2357 16.271 48.2357 15.4799V13.1432C48.2357 12.3522 48.877 11.7109 49.668 11.7109C50.459 11.7109 51.1003 12.3522 51.1003 13.1432V15.4799C51.1003 16.271 50.459 16.9122 49.668 16.9122Z"
          fill="#2768A3"
        />
        <path
          d="M62.7684 27.7318H60.4419C59.6509 27.7318 59.0096 27.0905 59.0096 26.2995C59.0096 25.5085 59.6509 24.8672 60.4419 24.8672H62.7684C63.5594 24.8672 64.2007 25.5085 64.2007 26.2995C64.2007 27.0905 63.5594 27.7318 62.7684 27.7318Z"
          fill="#2768A3"
        />
        <path
          d="M26.5501 35.8488C26.5501 31.2192 30.2538 30.8107 30.2538 29.0678C30.2538 27.9785 29.4368 27.461 28.3202 27.461C27.0403 27.461 25.9237 28.1419 24.9433 29.4763L22.7374 27.6789C23.7178 26.1539 25.5969 24.6016 28.5108 24.6016C31.2614 24.6016 33.5217 25.936 33.5217 28.4959C33.5217 31.9817 29.7091 31.8728 29.7091 35.8488H26.5501ZM26.3322 41.2682V37.6462H29.9542V41.2682H26.3322Z"
          fill="#2768A3"
        />
        <path
          d="M71.1155 58.7305C72.5682 55.9711 73.3332 52.8749 73.3332 49.7328C73.3332 39.1117 64.7273 30.4707 54.1493 30.4707C52.3832 30.4707 50.672 30.7117 49.0467 31.1625C48.458 19.9469 39.1854 11.0039 27.8716 11.0039C16.1791 11.0039 6.6665 20.5555 6.6665 32.296C6.6665 35.7931 7.52367 39.2391 9.15114 42.3049L7.11885 48.078C6.95765 48.5362 7.03955 49.0448 7.3363 49.4292C7.63304 49.8134 8.10322 50.0208 8.58864 49.981L15.2735 49.4247C18.9384 52.1505 23.2819 53.588 27.8716 53.588C30.38 53.588 32.8166 53.1574 35.1362 52.3061C36.3927 61.7151 44.4403 68.9949 54.1494 68.9949C58.2834 68.9949 62.1963 67.706 65.5024 65.2613L71.4491 65.7561C71.9332 65.7974 72.4045 65.5887 72.7014 65.2043C72.9981 64.8199 73.08 64.3113 72.9188 63.8531L71.1155 58.7305ZM16.589 46.825C16.3047 46.6018 15.9472 46.4944 15.5858 46.5242L10.5553 46.9428L12.0662 42.6512C12.2044 42.2587 12.1651 41.8255 11.9584 41.4643C10.3704 38.6884 9.53109 35.518 9.53109 32.296C9.53109 22.135 17.7587 13.8685 27.8715 13.8685C37.9518 13.8685 46.1588 22.0819 46.2114 32.198C39.7087 35.1776 35.141 41.6999 34.9705 49.2926C32.7239 50.2423 30.3385 50.7234 27.8715 50.7234C23.7386 50.7234 19.8372 49.3754 16.589 46.825ZM68.2009 59.0781L69.4823 62.7178L65.1914 62.3608C64.831 62.3312 64.4726 62.4383 64.1881 62.6616C61.298 64.9307 57.8267 66.1302 54.1493 66.1302C45.151 66.1302 37.8302 58.7743 37.8302 49.7328C37.8302 40.6913 45.151 33.3353 54.1493 33.3353C63.1478 33.3353 70.4686 40.6911 70.4686 49.7328C70.4686 52.6 69.7217 55.4212 68.3087 57.8914C68.102 58.2526 68.0627 58.6857 68.2009 59.0781Z"
          fill="black"
        />
        <path
          d="M53.2167 52.5149C53.2167 47.8852 56.9204 47.4767 56.9204 45.7338C56.9204 44.6445 56.1034 44.1271 54.9868 44.1271C53.7069 44.1271 52.5903 44.8079 51.6099 46.1423L49.4041 44.3449C50.3844 42.8199 52.2635 41.2676 55.1775 41.2676C57.928 41.2676 60.1884 42.602 60.1884 45.1619C60.1884 48.6478 56.3757 48.5388 56.3757 52.5149H53.2167ZM52.9988 57.9342V54.3122H56.6208V57.9342H52.9988Z"
          fill="#2768A3"
        />
      </g>
      <defs>
        <clipPath id="clip0_13526_179917">
          <rect
            width="66.6667"
            height="66.6667"
            fill="white"
            transform="translate(6.66663 6.66602)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default FaqIcon
