import { SVGProps } from "react"

const MenuArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={9}
    fill="none"
    {...props}
  >
    <path
      fill={props.fill}
      d="M7.19.604.702 7.09l1.513 1.514L7.19 3.63l4.973 4.973 1.514-1.514L7.189.604Z"
    />
  </svg>
)
export default MenuArrowDown
