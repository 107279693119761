export const sharedColors = {
  black: "#000000",
  white: "#FFFFFF",
  red: "#FF0000",
  darkRed: "#D10040",
  green: "#00FF00",
  darkBlue: "#153E69",
  primaryDarkBlue: "#1A2B68",
  bluishGray: "#BAD2D4",
  propBlue: "#2768A3",
  yellow: "#FCBB00",
  gray: "#6F6F6F",
  lightGray: "#6F6F6F",
  disableGray: "#696F7A",
  mint: "#040B14",
  blue: "#0050C9",
  lighterBlack: "#666666",
  darkGray: "#5C6474",
  lightGreen: "#66CC00",
  sliderBlue: "#2642A6",
  darkestBlue: "#1B1F2F",
  blueMain: "#0916BC",
  oldBrandBlue: "#2768A3",
  lightGrey: "#F7F7F7",
  darkGreen: "rgba(91, 117, 83, 1)",
  bonitaFooter: "#4A4A4A",
  boniteDarkBlue: "#0D0630",
}
