import { SVGProps } from "react"

const Pause = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_35_3866)">
        <rect x="8" y="4" width="32" height="32" rx="16" fill="white" />
        <path
          d="M28.6511 14.5H26.2775C25.9862 14.5 25.75 14.7093 25.75 14.9675V24.3182C25.75 24.5764 25.9862 24.7857 26.2775 24.7857H28.6511C28.9424 24.7857 29.1786 24.5764 29.1786 24.3182V14.9675C29.1786 14.7093 28.9424 14.5 28.6511 14.5Z"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.7937 14.5H19.4201C19.1287 14.5 18.8926 14.7093 18.8926 14.9675V24.3182C18.8926 24.5764 19.1287 24.7857 19.4201 24.7857H21.7937C22.085 24.7857 22.3211 24.5764 22.3211 24.3182V14.9675C22.3211 14.7093 22.085 14.5 21.7937 14.5Z"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_35_3866"
          x="0"
          y="0"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_35_3866"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_35_3866"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default Pause
