import { gql } from "@apollo/client"

type Props = {
  pageIDs: number[]
  projectIDs: number[]
}

function GET_SEARCH(props: Props) {
  const { pageIDs, projectIDs } = props

  const query = `
      query GetSearch($culture: String!, $route: String!) {
        projects: contentDescendantsByAbsoluteRoute(
          route: "/he-il/shared/general-information/projects"
          culture: $culture
          first: 1000
          where: {
            id:{in:[${projectIDs}]}
          }
        ) {
          nodes {
            id
            url
            contentType {
              alias
            }
            namedProperties {
              ... on IProject {
                thumbnailImageForLobbyPage {
                  mediaItems {
                    url
                  }
                }
                            projectType {
              value
            }
                text {
                  value
                }
                projectTitle {
                  value
                }
                groupSIte {
                  contentList {
                    key
                    id
                  }
                }
              }
            }
          }
        }
        pages: contentDescendantsByAbsoluteRoute(
          route: $route
          culture: $culture
          first: 10
          where: {
            id:{in:[${pageIDs}]}
          }
        ) {
          nodes {
            id
            url
            contentType {
              alias
            }
            namedProperties {
              ... on IPageInfoBlock {
                titleBreadcrumbs {
                  value
                }
              }
            }
          }
        }
      }


  `
  /* prettier-ignore */
  return gql(query)
}

export default GET_SEARCH
