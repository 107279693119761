"use client"

import styled from "@emotion/styled"
import { Breadcrumbs } from "@mui/material"
import Link from "next/link"

import NewText from "./NewText/NewText"
import { sharedColors } from "./theme/colors"

const StyledContainer = styled.div<{ noPadding?: boolean }>`
  padding: ${(props) => (props.noPadding ? "" : "12px 0 ")};
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => (props.noPadding ? "" : "16px 0 ")};
  }
`

const StyledLink = styled.div`
  text-decoration: none;
`

type BreadcrumbType = { title: string | JSX.Element; url: string }

type Props = {
  current: BreadcrumbType
  parents: BreadcrumbType[]
  children?: JSX.Element
  noPadding?: boolean
}

export const CustomBreadcrumbs: React.FC<Props> = ({
  current,
  parents,
  children,
  noPadding,
}) => {
  return (
    <StyledContainer noPadding={noPadding}>
      {children}

      <Breadcrumbs separator={"/"} aria-label="breadcrumb">
        {parents.map((item, index) => (
          <StyledLink key={index.toString()}>
            <Link
              style={{
                textDecoration: "none",
              }}
              href={item.url}
            >
              <NewText
                component="p"
                desktopFontSize={18}
                desktopFontWeight="400"
                desktopLineHeight="26px"
                mobileFontSize={14}
                mobileLineHeight="20.16px"
                color={sharedColors.gray}
              >
                {item.title}
              </NewText>
            </Link>
          </StyledLink>
        ))}
        <NewText
          component="p"
          desktopFontSize={18}
          desktopFontWeight="400"
          desktopLineHeight="26px"
          mobileFontSize={14}
          mobileLineHeight="20.16px"
          color={sharedColors.black}
        >
          {current.title}
        </NewText>
      </Breadcrumbs>
    </StyledContainer>
  )
}

export default CustomBreadcrumbs
