"use client"
import styled from "@emotion/styled"
import { Container } from "@mui/material"
import { ImageContainer } from "./ImageContainer"
import { Desktop, Mobile } from "./common/bit/MediaQueries"

const StyledSideTextImage = styled.div<{ $isSwitched?: boolean }>`
  img {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 88px;
    ${(p) => p.$isSwitched && "align-items: center"};
    img {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
`

type SideImageText = {
  isSwitched: boolean
  children: JSX.Element
  image: string
  flip: boolean
}

function SideImageText({ children, image, flip }: SideImageText) {
  return (
    <>
      <Desktop>
        <StyledSideTextImage $isSwitched={flip}>
          {!flip ? children : null}
          <ImageContainer
            desktopUrl={image}
            height={720}
            mobileHeight={260}
            mediumHeight={475}
          />
          {flip ? children : null}
        </StyledSideTextImage>
      </Desktop>
      <Mobile>
        <Container>
          <StyledSideTextImage $isSwitched={flip}>
            {children}
            <ImageContainer
              desktopUrl={image}
              height={720}
              mobileHeight={260}
            />
          </StyledSideTextImage>
        </Container>
      </Mobile>
    </>
  )
}

export default SideImageText
