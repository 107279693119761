export function getNonUmbracoUrl(url?: string, prePath?: string) {
  if (!url) {
    return "/"
  }
  if (url?.includes("pages/home")) {
    return url?.includes("en-us")
      ? prePath
        ? "/en" + prePath
        : "/en"
      : prePath
      ? prePath
      : "/"
  }
  if (url?.includes("/shared")) {
    return url?.includes("en-us")
      ? "/en" + url?.replace(/.*\/general-information/, "")
      : url?.replace(/.*\/general-information/, "") || "/"
  }
  if (!url?.includes("pages")) {
    return url
  }
  return url?.includes("en-us")
    ? "/en" +
        (prePath
          ? prePath + url?.replace(/.*\/pages/, "")
          : url?.replace(/.*\/pages/, ""))
    : (prePath
        ? prePath + url?.replace(/.*\/pages/, "")
        : url?.replace(/.*\/pages/, "")) || "/"
}
