import { SVGProps } from "react"
const NextArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={28}
    fill="none"
    {...props}
  >
    <path
      stroke="#BAD2D4"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M15.333 25.833 2 13.917m0 0L15.333 2M2 13.916h32"
    />
  </svg>
)
export default NextArrow
