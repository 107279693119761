import { SVGProps } from "react"
const LeftArrow = ({ fill = "black" }: SVGProps<SVGSVGElement>) => (
  <svg
    width="9"
    height="13"
    viewBox="0 0 9 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.760382 6.01739L6.55553 0.490899C6.83499 0.224387 7.2881 0.224387 7.5676 0.490899L8.24351 1.13548C8.52254 1.40157 8.52306 1.83282 8.2447 2.09952L3.65196 6.49999L8.2447 10.9005C8.52306 11.1672 8.52254 11.5984 8.24351 11.8645L7.5676 12.5091C7.28813 12.7756 6.83502 12.7756 6.55553 12.5091L0.760382 6.98258C0.480916 6.71604 0.480916 6.28393 0.760382 6.01739Z"
      fill={fill}
    />
  </svg>
)
export default LeftArrow
