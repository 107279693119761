import styled from "@emotion/styled"
import { Box } from "@mui/material"
import NewText from "../../NewText/NewText"
import { sharedColors } from "../../theme/colors"

const StyledEnvAndBuildTime = styled(Box)`
  margin-top: 15px;
`

function Env() {
  return (
    process.env.ENV !== "production" && (
      <StyledEnvAndBuildTime>
        <NewText
          textTransform="uppercase"
          desktopFontSize={14}
          component="p"
          desktopLineHeight="20px"
          desktopFontWeight="400"
          color={sharedColors.white}
        >
          {process.env.ENV}{" "}
          {process.env.BUILD_TIMESTAMP &&
            `- ${new Date(Number(process.env.BUILD_TIMESTAMP) * 1000 || "").toLocaleString()}`}
        </NewText>
      </StyledEnvAndBuildTime>
    )
  )
}

export default Env
