import styled from "@emotion/styled"
import moment from "moment"
import { NewText } from "ui"
import { sharedColors } from "ui/theme/colors"

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  ${(props) => props.theme.breakpoints.down("sm")} {
    overflow-x: auto; /* Enable horizontal scrolling */
    width: 100vw; /* Make it full width */
    white-space: nowrap; /* Prevent text wrapping */
    ::-webkit-scrollbar {
      display: none;
    }
    padding-inline-start: 15px;
  }
`

const StyledSlide = styled.div`
  display: flex;
`

const StyledLine = styled.div`
  height: 26px;
  width: 1px;
  background-color: #6f6f6f;
  margin-left: 16px;
  margin-right: 16px;
`
type Props = {
  stockBanner: any
  change: string
  lastUpdate: string
  cents: string | undefined
  StockRate: string | undefined
  baseRate: string | undefined
  page?: string
}

function StockDto({
  stockBanner,
  change,
  lastUpdate,
  StockRate,
  baseRate,
  cents,
  page,
}: Props) {
  return stockBanner?.lastTransaction && stockBanner?.securityLastRate ? (
    <StyledDiv>
      <StyledSlide>
        <NewText
          component={"p"}
          desktopFontSize={20}
          desktopLineHeight={"28px"}
          desktopFontWeight={400}
          color={sharedColors.black}
          mobileFontSize={18}
          mobileLineHeight={"26px"}
        >
          {`${StockRate} ${stockBanner?.securityLastRate} ${cents}`}
        </NewText>
        <StyledLine />
      </StyledSlide>
      <StyledSlide>
        <NewText
          component={"p"}
          desktopFontSize={20}
          desktopLineHeight={"28px"}
          desktopFontWeight={400}
          color={sharedColors.black}
          marginRight={"8px !important"}
          mobileFontSize={18}
          mobileLineHeight={"26px"}
        >
          {change}:
        </NewText>
        <NewText
          component={"p"}
          desktopFontSize={20}
          desktopLineHeight={"28px"}
          desktopFontWeight={400}
          color={stockBanner?.changeValue > 0 ? "#008015" : sharedColors.red}
          style={{ unicodeBidi: "plaintext" }}
          mobileFontSize={18}
          mobileLineHeight={"26px"}
        >
          {stockBanner?.changeValue}
        </NewText>
        <NewText
          component={"p"}
          desktopFontSize={20}
          desktopLineHeight={"28px"}
          desktopFontWeight={400}
          color={stockBanner?.changeValue > 0 ? "#008015" : sharedColors.red}
          style={{ unicodeBidi: "plaintext" }}
          marginRight={"8px !important"}
          marginLeft={"8px !important"}
          mobileFontSize={18}
          mobileLineHeight={"26px"}
        >
          |
        </NewText>
        <NewText
          component={"p"}
          desktopFontSize={20}
          desktopLineHeight={"28px"}
          desktopFontWeight={400}
          color={stockBanner?.changeValue > 0 ? "#008015" : sharedColors.red}
          style={{ unicodeBidi: "plaintext" }}
          mobileFontSize={18}
          mobileLineHeight={"26px"}
        >
          {" "}
          {stockBanner?.securityPercentageChange}%
        </NewText>

        <StyledLine />
      </StyledSlide>

      <StyledSlide>
        {page === "group" ? (
          <NewText
            component={"p"}
            desktopFontSize={20}
            desktopLineHeight={"28px"}
            desktopFontWeight={400}
            color={sharedColors.black}
            mobileFontSize={18}
            mobileLineHeight={"26px"}
          >
            {baseRate}:{"  "}
            {stockBanner?.basePrice}
          </NewText>
        ) : (
          <NewText
            component={"p"}
            desktopFontSize={20}
            desktopLineHeight={"28px"}
            desktopFontWeight={400}
            color={sharedColors.black}
            mobileFontSize={18}
            mobileLineHeight={"26px"}
          >
            {lastUpdate}:{"  "}
            {moment(stockBanner?.lastTransaction).format("DD-MM-YYYY")}
          </NewText>
        )}
      </StyledSlide>
    </StyledDiv>
  ) : null
}
export default StockDto
