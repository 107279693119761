"use client"
import { useState } from "react"

import styled from "@emotion/styled"
import { IconButton } from "@mui/material"
import NewText from "./NewText/NewText"
import { CollapseIcon, ExpandIcon } from "./icons"
import { sharedColors } from "./theme/colors"

type AccordionProps = {
  children: JSX.Element
  title: string
  index: number
}

const StyledAccordionWrapper = styled.div``
const StyledAccordionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1.5px solid ${sharedColors.black};
  button {
    height: 80px;
    width: 80px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    p {
      padding-left: 28px;
      padding-right: 28px;
    }
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    button {
      height: 53px;
      width: 53px;
    }
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    button {
      height: 80px;
      width: 80px;
    }
  }
`

function CustomAccordion({ children, title, index }: AccordionProps) {
  const [expand, setExpand] = useState(index === 0 ? true : false)

  return (
    <StyledAccordionWrapper>
      <StyledAccordionHeader>
        <NewText
          component="p"
          desktopFontSize={40}
          desktopFontWeight={500}
          color={sharedColors.black}
          mobileFontSize={20}
          smallDesktopFontSize={26}
          smallDesktopLineHeight="39px"
        >
          {title}
        </NewText>

        {expand ? (
          <IconButton
            onClick={() => {
              setExpand(false)
            }}
          >
            <ExpandIcon />
          </IconButton>
        ) : (
          <IconButton onClick={() => setExpand(true)}>
            <CollapseIcon />
          </IconButton>
        )}
      </StyledAccordionHeader>
      {expand ? children : null}
    </StyledAccordionWrapper>
  )
}

export default CustomAccordion
