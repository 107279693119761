import { SVGProps } from "react"
const FileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="22"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.48943 5.13323L12.5893 12.2331C13.4335 13.0773 14.8023 13.0773 15.6466 12.2331C16.4926 11.3871 16.4906 10.0148 15.6421 9.17126L10.1363 3.69803L8.75365 2.31536C7.07131 0.63302 4.3437 0.633021 2.66137 2.31536C0.979028 3.9977 0.979027 6.72531 2.66136 8.40764L4.02324 9.76953L9.18475 14.931C11.8547 17.7122 15.9003 18.1354 18.7289 15.4199C21.5229 12.7377 21.0493 8.71646 18.2243 5.89147L13.0831 0.749512"
      stroke="black"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default FileIcon
