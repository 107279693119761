"use client"
import styled from "@emotion/styled"

import { IconButton } from "@mui/material"
import { useRef, useState } from "react"
import { extractImage, extractProperty, getUmbracoMediaUrl } from "utils"
import { Desktop, Mobile } from "../common/bit/MediaQueries"
import Container from "../Container"
import VideoMuteIcon from "../icons/video/VideoMuteIcon"
import VideoPauseIcon from "../icons/video/VideoPauseIcon"
import VideoPlayIcon from "../icons/video/VideoPlayIcon"
import VideoUnmuteIcon from "../icons/video/VideoUnmuteIcon"
import ImageWithAlt from "../ImageWithAlt"

const StyledImagesContainer = styled.div<{ $paddingTop: boolean }>`
  padding-top: ${(p) => (p.$paddingTop ? `38px` : "0px")};
  ${(props) => props.theme.breakpoints.up("md")} {
  }
`

const StyledActiveImage = styled.div<{ isFullHeight: boolean }>`
  opacity: 1;
  position: relative;
  height: ${(p) => (p.isFullHeight ? "340px" : "392px")};
  ${(props) => props.theme.breakpoints.up("md")} {
    height: ${(p) => (p.isFullHeight ? "100%" : "500px")};
  }
`
const StyledVideoContainer = styled.div<{ $videoHeight?: string }>`
  opacity: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000917;
  height: 340px;

  ${(props) => props.theme.breakpoints.up("md")} {
    height: ${(p) => (p?.$videoHeight ? p?.$videoHeight : "100%")};

    overflow: hidden;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    > div {
      width: 100%;
      height: 100%;
    }
  }
`
const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${(props) => props.theme.breakpoints.up("md")} {
    position: absolute;
    inset: 0;
  }
`

const StyledContainer = styled(Container)`
  position: absolute;
  inset: 0;
`
const StyledButtonsWrapper = styled.div`
  position: relative;
  z-index: 1;
  direction: rtl;
  display: flex;
  position: absolute;
  inset-inline-start: 0;
  bottom: 20px;
  button {
    padding: 0;
    &:focus-visible {
      border: 2px dashed white;
      outline-offset: 2px;
      outline: 3px dashed #10059f;
    }
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    bottom: 0px;
    inset-inline-start: 8px;
  }
`

type Props = {
  data: any
  children?: JSX.Element
  isFullHeight?: boolean
  paddingTop?: boolean
  videoHeight?: string
}

export const TopBanner: React.FC<Props> = ({
  data,
  children,
  isFullHeight = false,
  paddingTop = false,
  videoHeight = "100%",
}) => {
  const desktopVideo = extractProperty(data, "desktopImage")
  const mobileVideo = extractProperty(data, "mobileImage")

  const desktopImage = extractImage(data, "desktopImage")
  const mobileImage = extractImage(data, "mobileImage")
  const videoOptional = extractProperty(data, "videoOptional")
  const videoMobile = extractProperty(data, "videoMobile")
  const videoRef = useRef<HTMLVideoElement>(null)
  const videoMobileRef = useRef<HTMLVideoElement>(null)
  const [isPlaying, setIsPlaying] = useState(true)
  const [isMuted, setIsMuted] = useState(true)

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play()
      setIsPlaying(true)
    }
    if (videoMobileRef.current) {
      videoMobileRef.current.play()
      setIsPlaying(true)
    }
  }

  const handlePause = () => {
    if (videoRef.current) {
      videoRef.current.pause()
      setIsPlaying(false)
    }
    if (videoMobileRef.current) {
      videoMobileRef.current.pause()
      setIsPlaying(false)
    }
  }

  const handleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = true
      setIsMuted(true)
    }
    if (videoMobileRef.current) {
      videoMobileRef.current.muted = true
      setIsMuted(true)
    }
  }

  const handleUnmute = () => {
    if (videoRef.current) {
      videoRef.current.muted = false
      setIsMuted(false)
    }
    if (videoMobileRef.current) {
      videoMobileRef.current.muted = false
      setIsMuted(false)
    }
  }
  // The rest of your existing Hero logic
  return (
    <StyledImagesContainer $paddingTop={paddingTop}>
      {!videoOptional && !videoMobile ? (
        <StyledActiveImage isFullHeight={isFullHeight}>
          {/* <StyledBackDrop /> */}
          <Desktop>
            <ImageWithAlt
              image={desktopImage}
              style={{ objectFit: "cover" }}
              fill
            />
          </Desktop>
          <Mobile>
            <ImageWithAlt
              image={mobileImage}
              style={{ objectFit: "cover" }}
              fill
            />
          </Mobile>
        </StyledActiveImage>
      ) : (
        <StyledVideoContainer $videoHeight={videoHeight}>
          <Desktop>
            <StyledVideo
              autoPlay
              loop
              muted={isMuted}
              poster={desktopVideo}
              ref={videoRef}
              playsInline
            >
              <source
                src={getUmbracoMediaUrl(videoOptional)}
                type="video/mp4"
              />
            </StyledVideo>
          </Desktop>
          <Mobile>
            <StyledVideo
              autoPlay
              loop
              muted={isMuted}
              poster={mobileVideo}
              ref={videoMobileRef}
              playsInline
            >
              <source src={getUmbracoMediaUrl(videoMobile)} type="video/mp4" />
            </StyledVideo>
          </Mobile>
          <StyledContainer>
            <StyledButtonsWrapper>
              <IconButton
                onClick={isPlaying ? handlePause : handlePlay}
                aria-label={isPlaying ? "עצירת הוידאו" : "ניגון הוידאו"}
              >
                {isPlaying ? <VideoPauseIcon /> : <VideoPlayIcon />}
              </IconButton>
              <IconButton
                onClick={isMuted ? handleUnmute : handleMute}
                aria-label={isMuted ? "השמעת הקול בוידאו" : "השתקת הקול בוידאו"}
              >
                {isMuted ? <VideoUnmuteIcon /> : <VideoMuteIcon />}
              </IconButton>
            </StyledButtonsWrapper>
          </StyledContainer>
        </StyledVideoContainer>
      )}
      <Container>{children}</Container>
    </StyledImagesContainer>
  )
}

export default TopBanner
