import { SVGProps } from "react"

const ContactMail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M1.042 7.5A4.792 4.792 0 0 1 5.833 2.71h8.334a4.792 4.792 0 0 1 4.791 4.792v5a4.792 4.792 0 0 1-4.791 4.791H5.832a4.792 4.792 0 0 1-4.792-4.791v-5ZM5.833 3.96a3.542 3.542 0 0 0-3.542 3.542v5a3.542 3.542 0 0 0 3.542 3.541h8.334a3.542 3.542 0 0 0 3.541-3.541v-5a3.542 3.542 0 0 0-3.541-3.542H5.832ZM4.5 7.126A.625.625 0 0 1 5.375 7l4 3c.37.277.88.277 1.25 0l4-3a.625.625 0 1 1 .75 1l-4 3a2.292 2.292 0 0 1-2.75 0l-4-3a.625.625 0 0 1-.125-.875Z"
      clipRule="evenodd"
    />
  </svg>
)
export default ContactMail
