const FormSubmitFailIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.7252 43.1718C51.0752 47.222 47.222 51.0752 43.1718 49.7251L3.54289 36.5155C-0.871347 35.0441 -1.2587 28.9511 2.93368 26.9325L17.2479 20.0405C18.4683 19.4529 19.4531 18.4682 20.0407 17.2477L26.9326 2.9337C28.9511 -1.25868 35.0441 -0.871371 36.5155 3.54289L49.7252 43.1718ZM44.4321 45.9444C45.3668 46.2559 46.256 45.3667 45.9444 44.4321L32.7348 4.80314C32.3952 3.78448 30.9891 3.69508 30.5233 4.66257L23.6314 18.9766C23.4355 19.3836 23.213 19.7748 22.9662 20.1482L33.9907 31.1727C34.7689 31.9509 34.7689 33.2126 33.9907 33.9907C33.2125 34.7689 31.9508 34.7689 31.1727 33.9907L20.1481 22.9662C19.7748 23.2129 19.3837 23.4353 18.9768 23.6312L4.66256 30.5233C3.69509 30.9891 3.78447 32.3952 4.80315 32.7347L44.4321 45.9444Z"
      fill="#C92418"
    />
  </svg>
)
export default FormSubmitFailIcon
