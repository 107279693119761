import styled from "@emotion/styled"
import Link from "next/link"
import React from "react"
import { extractProperty, footerMenuClick, getNonUmbracoUrl } from "utils"
import NewText from "../../NewText/NewText"
import { Desktop } from "../../common/bit/MediaQueries"
import { ContactCalling, ContactLocation, ContactMail } from "../../icons"
import { sharedColors } from "../../theme/colors"

const StyledLinkList = styled.ul`
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  a {
    text-decoration: none;
  }
`
const StyledLinkK = styled(Link)``
const NewTextWrapper = styled.div`
  display: inline-block;
  border-bottom: 1px solid transparent;
  ${StyledLinkK}:hover & {
    border-bottom: 1px solid black;
  }
`

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(205px, 1fr)
  ); /* Adjust the 'minmax' values as needed */
  grid-auto-rows: minmax(
    50px,
    auto
  ); /* This sets the minimum and maximum row size */
  gap: 16px;
  row-gap: 50px;
  width: 100%;
`
const ContactBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
const ContactItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
type Props = {
  desktopItems: any
  contactInfo: any
}

function GridDesktop({ desktopItems, contactInfo }: Props) {
  const contact = contactInfo?.[0]
  const contactInfoTitle = extractProperty(contact, "contactInfoTitle")
  const phoneNumber = extractProperty(contact, "phoneNumber")?.[0]?.name
  const email = extractProperty(contact, "email")?.[0]?.name
  const address = extractProperty(contact, "address")
  return (
    <Desktop>
      <StyledGrid>
        {desktopItems.map((main: any, index: number) => {
          const title = extractProperty(main, "title")
          const children = extractProperty(main, "urls")

          return (
            <React.Fragment key={index.toString()}>
              <StyledLinkList>
                <NewText
                  desktopFontSize={20}
                  component="h2"
                  desktopLineHeight="28px"
                  desktopFontWeight="500"
                  color={sharedColors.black}
                >
                  {title}
                </NewText>

                {children.map((item: any, ind: number) => {
                  const title = extractProperty(item, "title")
                  const link = extractProperty(item, "link")
                  const linkTitle = extractProperty(item, "linkTitle")
                  return (
                    <StyledLinkK
                      key={ind.toString()}
                      href={getNonUmbracoUrl(link?.[0]?.url) || "/"}
                      onClick={() => footerMenuClick(linkTitle, "")}
                    >
                      <NewTextWrapper>
                        <NewText
                          key={ind}
                          desktopFontSize={18}
                          component="li"
                          desktopLineHeight="25px"
                          desktopFontWeight={title ? "600" : "400"}
                          color={sharedColors.black}
                        >
                          {linkTitle}
                        </NewText>
                      </NewTextWrapper>
                    </StyledLinkK>
                  )
                })}
                {index === desktopItems.length - 1 ? (
                  <>
                    <NewText
                      desktopFontSize={18}
                      component="h2"
                      desktopLineHeight="26px"
                      desktopFontWeight="500"
                      marginTop={"24px"}
                      marginBottom={"16px"}
                      paddingBottom={"8px"}
                      borderBottom={"solid black 1px"}
                      width={"max-content"}
                      color={sharedColors.black}
                      textTransform="uppercase"
                    >
                      {contactInfoTitle}
                    </NewText>
                    <ContactBox>
                      {!!phoneNumber && (
                        <ContactItem>
                          <ContactCalling />
                          <NewTextWrapper>
                            <NewText
                              desktopFontSize={18}
                              component="li"
                              desktopLineHeight="26px"
                              desktopFontWeight={"400"}
                              color={sharedColors.black}
                              textTransform="uppercase"
                            >
                              {phoneNumber}
                            </NewText>
                          </NewTextWrapper>
                        </ContactItem>
                      )}
                      {!!email && (
                        <ContactItem>
                          <ContactMail />
                          <NewTextWrapper>
                            <NewText
                              desktopFontSize={18}
                              component="li"
                              desktopLineHeight="26px"
                              desktopFontWeight={"400"}
                              color={sharedColors.black}
                              textTransform="uppercase"
                            >
                              {email}
                            </NewText>
                          </NewTextWrapper>
                        </ContactItem>
                      )}
                      {address && (
                        <ContactItem>
                          <ContactLocation />
                          <NewTextWrapper>
                            <NewText
                              desktopFontSize={18}
                              component="li"
                              desktopLineHeight="26px"
                              desktopFontWeight={"400"}
                              color={sharedColors.black}
                              textTransform="uppercase"
                            >
                              {address}
                            </NewText>
                          </NewTextWrapper>
                        </ContactItem>
                      )}
                    </ContactBox>
                  </>
                ) : null}
              </StyledLinkList>
            </React.Fragment>
          )
        })}
      </StyledGrid>
    </Desktop>
  )
}

export default GridDesktop
