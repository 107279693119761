const WhiteCircleIcon = () => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_14630_216364)">
        <rect
          width="40"
          height="40"
          rx="20"
          transform="matrix(-1 0 0 1 48 4)"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.1071 18.1078C22.4122 17.8027 22.9068 17.8027 23.2119 18.1078L28 22.8959L32.7881 18.1078C33.0932 17.8027 33.5878 17.8027 33.8929 18.1078C34.198 18.4129 34.198 18.9075 33.8929 19.2126L29.1049 24.0007L33.8922 28.788C34.1973 29.0931 34.1973 29.5878 33.8922 29.8929C33.5871 30.198 33.0924 30.198 32.7873 29.8929L28 25.1056L23.2127 29.8929C22.9076 30.198 22.4129 30.198 22.1078 29.8929C21.8027 29.5878 21.8027 29.0931 22.1078 28.7881L26.8952 24.0007L22.1071 19.2126C21.802 18.9075 21.802 18.4129 22.1071 18.1078Z"
          fill="black"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_14630_216364"
          x="0"
          y="0"
          width="56"
          height="56"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_14630_216364"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_14630_216364"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default WhiteCircleIcon
