"use client"

import styled from "@emotion/styled"
import { Button, ButtonProps } from "@mui/material"
import NewText from "./NewText/NewText"
import { LeftArrow, RightArrow } from "./icons"
import { sharedColors } from "./theme/colors"

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) =>
    !["$reverse", "$justifyContent", "$color"].includes(prop),
})<{
  $reverse?: boolean
  $justifyContent?: string
  $color?: string
}>`
  border-radius: 24px;
  display: flex;
  justify-content: ${(p) =>
    p.$justifyContent ? p.$justifyContent : "space-between"};
  position: relative;
  flex-direction: ${(p) => (p.$reverse ? "row-reverse" : "row")};
  text-decoration: none;
  svg {
    ${(p) => (p.$reverse ? "left: 20px" : "right: 20px")};
    ${(p) => p.$reverse && "transform: rotate(180deg)"};
    path {
      fill: ${(p) => p.$color};
    }
  }
  :hover {
    background-color: transparent;
    text-decoration: none;
  }
`
const StyledUnderlineTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

type ButtonWithArrowProps = {
  title: string
  type?: "button" | "reset" | "submit" | undefined
  onClick?: () => void
  reverse?: boolean
  justifyContent?: string
  desktopFontSize?: number
  mobileFontSize?: number
  smallDesktopFontSize?: number
  color?: string
  sx?: ButtonProps["sx"]
  locale?: string
}

export const ButtonWithArrow: React.FC<ButtonWithArrowProps> = ({
  title,
  type = "button",
  onClick,
  reverse = false,
  justifyContent = "space-between",
  desktopFontSize = 18,
  mobileFontSize = 18,
  smallDesktopFontSize = 18,
  color = sharedColors.black,
  sx,
  locale,
}) => {
  return (
    <StyledButton
      type={type}
      onClick={onClick && onClick}
      $reverse={reverse}
      $justifyContent={justifyContent}
      $color={color}
      sx={sx}
    >
      <StyledUnderlineTextContainer>
        <NewText
          mobileFontSize={mobileFontSize}
          desktopFontSize={desktopFontSize}
          component="div"
          desktopLineHeight="128%"
          desktopFontWeight="500"
          color={color}
          smallDesktopFontSize={smallDesktopFontSize}
          smallDesktopLineHeight="20px"
          marginRight={reverse ? "0px" : "8px"}
          style={{
            borderBottom: "1px solid black",
          }}
        >
          {title}
        </NewText>
      </StyledUnderlineTextContainer>
      {locale === "en" ? <RightArrow /> : <LeftArrow />}
    </StyledButton>
  )
}

export default ButtonWithArrow
