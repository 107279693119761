import { SVGProps } from "react"

interface CheckMark2IconProps extends SVGProps<SVGSVGElement> {
  state: boolean
}

const CheckMark2Icon = ({ state }: CheckMark2IconProps) => (
  <>
    {state ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
      >
        <path
          d="M13.3327 4.5L5.99935 11.8333L2.66602 8.5"
          stroke="#009262"
          strokeWidth="1.1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
      >
        <path
          d="M12 4.5L4 12.5"
          stroke="#C92418"
          strokeWidth="1.1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 4.5L12 12.5"
          stroke="#C92418"
          strokeWidth="1.1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )}
  </>
)
export default CheckMark2Icon
