import React, { SVGProps } from "react"
import Play from "./Play"
import PlayHover from "./PlayHover"

const VideoPlayIcon = (props: SVGProps<SVGSVGElement>) => {
  const [isHovered, setIsHovered] = React.useState(false)
  return (
    <span
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      {!isHovered ? <Play {...props} /> : <PlayHover {...props} />}
    </span>
  )
}

export default VideoPlayIcon
