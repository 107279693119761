"use client"
import styled from "@emotion/styled"
import Image from "next/image"
import { getUmbracoMediaUrl } from "utils"
import Container from "./Container"
import NewText from "./NewText/NewText"
import { Desktop, Mobile } from "./common/bit/MediaQueries"

const StyledDiv = styled.div`
  height: 587px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
  text-align: center;
  > div {
    width: 100%;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    p {
      width: 100%;
    }
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 50px;
    height: 860px;
    img {
      margin-top: 50px;
    }
    > div {
      width: auto;
    }
  }
`

const StyledImageWrapper = styled.div`
  position: relative;
  height: 256px;
  width: 100%;
  margin-top: 50px;
`

type MessagePageProps = {
  text: string
  imageDesktop: string
  imageMobile: string
  imageWidth: number
  imageHeight: number
}

function MessagePage({
  text,
  imageDesktop,
  imageMobile,
  imageWidth,
  imageHeight,
}: MessagePageProps) {
  return (
    <Container>
      <StyledDiv>
        <Mobile>
          <NewText
            component={"p"}
            desktopFontSize={24}
            desktopFontWeight={500}
            desktopLineHeight={"26px"}
            mobileLineHeight={"34px"}
          >
            {text.split(" ")[0]}
            <br />
            {text.split(" ").slice(1).join(" ")}
          </NewText>
        </Mobile>
        <Desktop>
          <NewText
            component={"p"}
            desktopFontSize={24}
            desktopFontWeight={500}
            desktopLineHeight={"26px"}
            mobileLineHeight={"34px"}
          >
            {text}
          </NewText>
        </Desktop>
        <Desktop>
          <Image
            src={getUmbracoMediaUrl(imageDesktop)}
            alt="error"
            width={imageWidth}
            height={imageHeight}
          />
        </Desktop>
        <Mobile>
          <StyledImageWrapper>
            <Image src={getUmbracoMediaUrl(imageMobile)} alt="error" fill />
          </StyledImageWrapper>
        </Mobile>
      </StyledDiv>
    </Container>
  )
}

export default MessagePage
