"use client"

import styled from "@emotion/styled"
import Link from "next/link"
import { useState } from "react"
import { extractProperty, footerMenuClick } from "utils"
import { Desktop } from "../../common/bit/MediaQueries"
import { SocialIcon } from "../../icons"

const StyledSocials = styled.div`
  display: flex;
  gap: 24px;
`
const Social = styled.div<{ hover: boolean }>`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
`

function Icon({ type }: { type: "instagram" | "linkedin" | "facebook" }) {
  const [hover, setHover] = useState(false)
  return (
    <Social
      hover={hover}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <SocialIcon type={type} hover={hover} />
    </Social>
  )
}
type Props = {
  desktopSocialUrls: any
}

function SocialsDesktop({ desktopSocialUrls }: Props) {
  return (
    <Desktop>
      <StyledSocials>
        {desktopSocialUrls.map((item: any, index: number) => {
          const link = extractProperty(item, "link")

          const type = extractProperty(item, "selectSocialIcon") as
            | "instagram"
            | "linkedin"
            | "facebook"

          if (!link?.[0]?.url) return null

          return (
            <Link
              key={index.toString()}
              style={{ width: 40, height: 40 }}
              href={link?.[0]?.url || "#"}
              onClick={() => footerMenuClick(type, "")}
              target="_blank"
              aria-label={
                type === "instagram"
                  ? "אינסטגרם"
                  : type === "facebook"
                  ? "פייסבוק "
                  : "לינקדאין"
              }
            >
              <Icon type={type} />
            </Link>
          )
        })}
      </StyledSocials>
    </Desktop>
  )
}

export default SocialsDesktop
