import { SVGProps } from "react"
const LocationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 10.4167C5 6.46601 8.23851 3.25 12.25 3.25C16.2615 3.25 19.5 6.46601 19.5 10.4167C19.5 12.8629 18.0387 15.4619 16.2814 17.5046C15.414 18.513 14.5037 19.351 13.7267 19.9302C13.3376 20.2203 12.9939 20.4365 12.7158 20.5765C12.577 20.6464 12.4639 20.6926 12.3768 20.7203C12.2941 20.7465 12.2548 20.7496 12.2503 20.75C12.2498 20.75 12.2497 20.75 12.25 20.75C12.2503 20.75 12.2502 20.75 12.2497 20.75C12.2452 20.7496 12.2059 20.7465 12.1232 20.7203C12.0361 20.6926 11.923 20.6464 11.7842 20.5765C11.5061 20.4365 11.1624 20.2203 10.7733 19.9302C9.99629 19.351 9.08605 18.513 8.21856 17.5046C6.46128 15.4619 5 12.8629 5 10.4167ZM12.25 1.75C7.42493 1.75 3.5 5.62281 3.5 10.4167C3.5 13.3982 5.23872 16.3409 7.08144 18.4829C8.01395 19.5669 9.00371 20.482 9.87674 21.1328C10.3126 21.4577 10.7314 21.7258 11.1095 21.9162C11.464 22.0947 11.8688 22.25 12.25 22.25C12.6312 22.25 13.036 22.0947 13.3905 21.9162C13.7686 21.7258 14.1874 21.4577 14.6233 21.1328C15.4963 20.482 16.486 19.5669 17.4186 18.4829C19.2613 16.3409 21 13.3982 21 10.4167C21 5.62281 17.0751 1.75 12.25 1.75ZM14.5 10C14.5 8.75736 13.4926 7.75 12.25 7.75C11.0074 7.75 10 8.75736 10 10C10 11.2426 11.0074 12.25 12.25 12.25C13.4926 12.25 14.5 11.2426 14.5 10ZM12.25 6.25C14.3211 6.25 16 7.92893 16 10C16 12.0711 14.3211 13.75 12.25 13.75C10.1789 13.75 8.5 12.0711 8.5 10C8.5 7.92893 10.1789 6.25 12.25 6.25Z"
      fill={props.fill || "black"}
    />
  </svg>
)
export default LocationIcon
