"use client"
import styled from "@emotion/styled"
import NewText from "../NewText/NewText"
import SearchInput from "../SearchInput/SearchInput"
import { sharedColors } from "../theme/colors"

const StyledTextContainer = styled.div`
  width: calc(100% - 32px);
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 100%;
    transform: translateX(0);
    left: 0px;
    width: 490px;
  }
`

type Props = {
  title: string | undefined
  paragraph: string | undefined
}

export const PositionedTextAndSearch: React.FC<Props> = ({
  title,
  paragraph,
}) => {
  return (
    <StyledTextContainer>
      <NewText
        desktopFontSize={48}
        desktopLineHeight="60px"
        mobileFontSize={40}
        mobileFontWeight="600"
        mobileLineHeight={"110%"}
        component="h1"
        desktopFontWeight="600"
        color={sharedColors.white}
      >
        {title}
      </NewText>
      <NewText
        desktopFontSize={18}
        desktopLineHeight="1.45"
        mobileFontSize={16}
        mobileFontWeight="400"
        mobileLineHeight={"125%"}
        component="div"
        desktopFontWeight="400"
        color={sharedColors.white}
        dangerouslySetInnerHTML={{ __html: paragraph! }}
      />
      <SearchInput placeholder="חיפוש לפי עיר / אזור / שם פרויקט..." />
    </StyledTextContainer>
  )
}

export default PositionedTextAndSearch
