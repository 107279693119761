"use client"

import { useCallback, useEffect, useRef, useState } from "react"

type Props = {
  inView: boolean
  number: string | number
  maximumFractionDigits?: number
}

const TransitionNumbersCounter = ({
  inView,
  number,
  maximumFractionDigits = 1,
}: Props) => {
  const numberValue =
    typeof number === "string" ? parseInt(number.replace(",", "")) : number

  const accumulator = numberValue / 50
  const [state, setState] = useState<number | string>(0)
  const [finished, setFinished] = useState(false)
  const valueRef = useRef(0)
  const tickTime = accumulator < 1 ? 35 : 50

  const updateCounterState = useCallback(() => {
    if (valueRef.current < numberValue) {
      const result =
        accumulator < 1
          ? Math.ceil((valueRef.current + accumulator) * 10) / 10
          : Math.ceil(valueRef.current + accumulator)
      if (result > numberValue) return setState(number)
      setState(result)
      valueRef.current = result
    } else {
      setFinished(true)
    }
    setTimeout(updateCounterState, tickTime)
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [])
  useEffect(() => {
    if (inView) {
      updateCounterState()
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [inView])

  if (isNaN(numberValue)) return number
  return (
    <>
      {finished
        ? number
        : state.toLocaleString("he-IL", {
            maximumFractionDigits,
          })}
    </>
  )
}

export default TransitionNumbersCounter
