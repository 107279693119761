"use client"
import styled from "@emotion/styled"
import ImageContainer from "./ImageContainer"

const StyledWrapper = styled.div<{ $isSwitched: boolean; $reverse?: boolean }>`
  display: flex;
  flex-direction: ${(p) => (p.$reverse ? "column-reverse" : "column")};
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 54px;
    flex-direction: ${(p) => (p.$isSwitched ? "row-reverse" : "row")};
    align-items: center;
    > div {
      width: 50%;
    }
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    gap: 81px;
  }
`

type CenterImageTextProps = {
  src: string
  children: JSX.Element
  height: number
  mobileHeight: number
  isSwitched: boolean
  mobileSrc?: string
  reverse?: boolean
  mediumHeight?: number
}

function CenterImageText({
  src,
  children,
  height,
  mobileHeight,
  isSwitched,
  mobileSrc,
  mediumHeight,
  reverse = true,
}: CenterImageTextProps) {
  return (
    <StyledWrapper $isSwitched={isSwitched} $reverse={reverse}>
      {children}
      <ImageContainer
        desktopUrl={src}
        height={height}
        mobileHeight={mobileHeight}
        mobileUrl={mobileSrc}
        mediumHeight={mediumHeight}
      />
    </StyledWrapper>
  )
}

export default CenterImageText
