"use client"

import { useState, useEffect } from "react"

type Props = {
  apiCall: any
  data?: any
}

export const useApi = ({ apiCall, data }: Props) => {
  const [response, setResponse] = useState(null)
  const [error, setError] = useState("")
  const [loading, setLoading] = useState<boolean>(true)

  const fetchData = () => {
    apiCall(data)
      .then((res: any) => {
        setResponse(res.data?.body)
      })
      .catch((err: any) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  return [response, error, loading]
}
