import { SVGProps } from "react"

const ContactLocation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M3.958 8.681c0-3.292 2.7-5.972 6.042-5.972 3.343 0 6.042 2.68 6.042 5.972 0 2.039-1.218 4.205-2.682 5.907-.723.84-1.482 1.539-2.13 2.021-.324.242-.61.422-.842.539-.115.058-.21.096-.282.12a.498.498 0 0 1-.106.024c-.004 0-.037-.003-.105-.025a1.978 1.978 0 0 1-.283-.12 5.956 5.956 0 0 1-.842-.538c-.648-.483-1.406-1.18-2.13-2.021C5.177 12.886 3.96 10.72 3.96 8.68ZM10 1.46c-4.02 0-7.292 3.227-7.292 7.222 0 2.485 1.45 4.937 2.985 6.722.777.903 1.602 1.666 2.33 2.208.363.271.712.494 1.027.653.295.149.632.278.95.278.318 0 .655-.13.95-.278a7.168 7.168 0 0 0 1.028-.653c.727-.542 1.552-1.305 2.33-2.208 1.535-1.785 2.984-4.237 2.984-6.722 0-3.995-3.27-7.222-7.292-7.222Zm1.875 6.875a1.875 1.875 0 1 0-3.75 0 1.875 1.875 0 0 0 3.75 0ZM10 5.209a3.125 3.125 0 1 1 0 6.25 3.125 3.125 0 0 1 0-6.25Z"
      clipRule="evenodd"
    />
  </svg>
)
export default ContactLocation
