"use client"
import styled from "@emotion/styled"

const StyledContainer = styled.div<{
  $rightGap?: number
  $leftGap?: number
  $mediumRightGap?: number
  $mobileGap?: number
}>`
  display: grid;
  ${(p) =>
    p.$rightGap
      ? `grid-template-columns: ${p.$mobileGap}px calc(100% - ${p.$mobileGap}px)`
      : p.$leftGap
      ? `grid-template-columns: calc(100% - ${p.$mobileGap}px) ${p.$mobileGap}px`
      : `grid-template-columns: ${p.$mobileGap}px calc(100% - ${p.$mobileGap}px)`};

  ${(props) => props.theme.breakpoints.up("md")} {
    grid-template-columns: ${(p) =>
      p.$mediumRightGap
        ? `${p.$mediumRightGap}px calc(100% - ${p.$mediumRightGap}px)`
        : p.$leftGap
        ? `calc(100% - ${p.$leftGap}px) ${p.$leftGap}px `
        : "150px calc(100% - 150px)"};
  }
  ${(props) => props.theme.breakpoints.up("xl")} {
    grid-template-columns: ${(p) =>
      p.$rightGap
        ? `${p.$rightGap}px calc(100% - ${p.$rightGap}px)`
        : p.$leftGap
        ? `calc(100% - ${p.$leftGap}px) ${p.$leftGap}px `
        : "150px calc(100% - 150px)"};
  }
`
type MainContainerProps = {
  children: JSX.Element
  rightGap?: number
  leftGap?: number
  mediumRightGap?: number
  mobileGap?: number
}

export const MainContainer: React.FC<MainContainerProps> = ({
  children,
  rightGap = 0,
  leftGap = 0,
  mediumRightGap = 0,
  mobileGap = 20,
}: MainContainerProps) => {
  return (
    <StyledContainer
      $rightGap={rightGap}
      $leftGap={leftGap}
      $mediumRightGap={mediumRightGap}
      $mobileGap={mobileGap}
    >
      {rightGap || mediumRightGap ? <div /> : null}
      {children}
      {leftGap ? <div /> : null}
    </StyledContainer>
  )
}

export default MainContainer
