import { SVGProps } from "react"

const Play = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_35_3851)">
        <rect x="8" y="4" width="32" height="32" rx="16" fill="white" />
        <path
          d="M30.6794 19.3925L30.6816 19.3937C30.7625 19.4392 30.8252 19.5009 30.8668 19.5696C30.9083 19.638 30.9286 19.7123 30.9286 19.7857C30.9286 19.8591 30.9083 19.9334 30.8668 20.0018C30.8252 20.0705 30.7625 20.1323 30.6816 20.1777L30.6794 20.179L21.3306 25.4912L21.3306 25.4912L21.3236 25.4953C21.2438 25.5423 21.1488 25.5696 21.049 25.5713C20.9493 25.5731 20.8529 25.5491 20.7708 25.5045L20.7708 25.5044L20.7598 25.4988C20.6747 25.4553 20.6086 25.3938 20.5646 25.3244L20.1423 25.5921L20.5646 25.3244C20.5209 25.2554 20.4998 25.18 20.5 25.1056V25.104V14.4674H20.5L20.5 14.4658C20.4998 14.3914 20.5209 14.316 20.5646 14.247C20.6086 14.1776 20.6747 14.1161 20.7598 14.0727L20.7599 14.0728L20.7708 14.0669C20.8529 14.0223 20.9493 13.9984 21.049 14.0001C21.1487 14.0018 21.2438 14.0291 21.3236 14.0761L21.3235 14.0762L21.3306 14.0802L30.6794 19.3925ZM20 14.4674V25.104V14.4674Z"
          stroke="black"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_35_3851"
          x="0"
          y="0"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_35_3851"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_35_3851"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default Play
