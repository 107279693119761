import { Culture } from "utils"
import GroupValues from "./GroupValues"
import ImageTextBlock from "./ImageTextBlock"
import MainTitle from "./MainTitle"

const defaultComponents = {
  imageTextBlock: ImageTextBlock,
  pgeMainTitle: MainTitle,
  groupValues: GroupValues,
}

const BlockRenderer = ({
  blockType,
  blockData,
  components,
  children,
  culture,
  locale,
  props,
}: {
  blockType: string
  blockData: any
  components: any
  children?: any
  props?: any
  locale?: string
  culture?: Culture
}) => {
  const effectiveComponents = { ...defaultComponents, ...components }
  const ComponentToRender = effectiveComponents[blockType]
  if (!ComponentToRender) {
    return null
    // } else if (blockType === "contentText" && !hasBanner) {
    //   return (
    //     <StyledWrapper>
    //       <ComponentToRender
    //         data={blockData}
    //         locale={locale}
    //         culture={culture}
    //         {...props}
    //       >
    //         {children}
    //       </ComponentToRender>
    //     </StyledWrapper>
    //   )
  }
  return (
    <ComponentToRender
      data={blockData}
      locale={locale}
      culture={culture}
      {...props}
    >
      {children}
    </ComponentToRender>
  )
}

export default BlockRenderer
