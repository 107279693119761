"use client"
import styled from "@emotion/styled"
import moment from "moment"
import Link from "next/link"
import { buttonClick, extractProperty, getUmbracoPDFUrl } from "utils"
import NewText from "../NewText/NewText"
import LinkButton from "../TitleTextLink/LinkButton"
import { sharedColors } from "../theme/colors"
import { styleObjectToCss } from "../transitions/utils"
import FileIcon2 from "./FileIcon2"
const StyledReportDiv = styled.div`
  box-shadow: 0px 4px 12px 0px #00000029;
  padding: 16px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StyledBottomDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  svg {
    margin-right: 4px;
  }
  min-width: 50%;
`

const StyledTextwrapper = styled.div``

const StyledLinksWrapper = styled.div<{ $width: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: ${(p) => p.$width};
`

const StyledLinkDiv = styled.div`
  display: flex;
  align-items: center;
`

type Props = {
  data: any
  mainTitle: string
}

export const ReportsWrapper = ({ data, mainTitle }: Props) => {
  const title = extractProperty(data, "title")
  const content = extractProperty(data, "content")
  const date = extractProperty(data, "date")
  const pdf = extractProperty(data, "pdf")
  const link = extractProperty(data, "link")
  return (
    <StyledReportDiv>
      <StyledTextwrapper>
        <NewText
          desktopFontSize={24}
          desktopFontWeight="600"
          desktopLineHeight="34px"
          mobileFontSize={18}
          mobileFontWeight="600"
          mobileLineHeight={"24px"}
          component="h2"
          color={"black"}
        >
          {title}
        </NewText>
        <NewText
          desktopFontSize={24}
          desktopFontWeight="400"
          desktopLineHeight="35px"
          mobileFontSize={18}
          mobileFontWeight="400"
          mobileLineHeight={"24px"}
          component="h3"
          color={"black"}
        >
          {content}
        </NewText>
      </StyledTextwrapper>
      <StyledBottomDiv>
        {pdf && pdf.length > 0 && (
          <StyledLinksWrapper
            $width={link && link.length > 0 ? "100%" : "auto"}
          >
            <StyledLink
              href={getUmbracoPDFUrl(pdf[0]?.url) || ""}
              target="_blank"
              onClick={() => buttonClick(pdf[0]?.name, mainTitle)}
            >
              <FileIcon2 width={20} height={20} />
              <NewText
                desktopFontSize={18}
                desktopFontWeight="400"
                desktopLineHeight="24px"
                mobileFontSize={14}
                style={{ borderBottom: "1px solid black" }}
                mobileFontWeight="400"
                mobileLineHeight={"24px"}
                component="p"
                color={sharedColors.black}
              >
                {pdf[0]?.name}
              </NewText>
            </StyledLink>
          </StyledLinksWrapper>
        )}
        {link && link.length > 0 && (
          <StyledLinkDiv>
            <FileIcon2 width={20} height={20} />

            <LinkButton
              variant="text"
              link={link}
              mainTitle={mainTitle}
              cssString={styleObjectToCss({
                width: "auto",
                paddingTop: 0,
                maxWidth: "510px",
              })}
              customSx={{
                fontWeight: "400",
                "& span": {
                  maxWidth: "400px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "inline-block",
                  whiteSpace: "nowrap",
                },
              }}
            />
          </StyledLinkDiv>
        )}
        {data && date.length > 0 && (
          <NewText
            desktopFontSize={18}
            desktopFontWeight="400"
            desktopLineHeight="26px"
            mobileFontSize={16}
            mobileFontWeight="400"
            mobileLineHeight={"20px"}
            component="p"
            color={sharedColors.gray}
          >
            {moment(date).format("DD.MM.YYYY")}
          </NewText>
        )}
      </StyledBottomDiv>
    </StyledReportDiv>
  )
}

export default ReportsWrapper
