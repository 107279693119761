import Image, { ImageProps } from "next/image"
import React from "react"
import { getUmbracoMediaUrl } from "utils"

// Define the types for the component's props, extending the Next.js ImageProps
type CustomImageProps = Omit<ImageProps, "src" | "alt"> & {
  image?: {
    url?: string
    altText?: string
  }
}

// Functional component that accepts props and renders an image using next/image
const ImageWithAlt: React.FC<CustomImageProps> = ({ image, ...props }) => {
  return (
    <Image
      src={getUmbracoMediaUrl(image?.url) || ""} // Provide a default empty string if url is undefined
      alt={image?.altText || ""} // Provide a default empty string if altText is undefined
      {...props} // Spread any additional props to the Image component
    />
  )
}

export default ImageWithAlt
