import * as React from "react"
const FileIcon2 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    fill="none"
    viewBox="0 0 60 73"
    {...props}
  >
    <path
      fill={props.fill || "black"}
      d="M16.67 5.667c-5.984 0-10.834 4.85-10.834 10.833v40c0 5.983 4.85 10.833 10.833 10.833h26.667c5.983 0 10.833-4.85 10.833-10.833V25.667h-7.5a9.167 9.167 0 0 1-9.166-9.167V5.713a4.166 4.166 0 0 0-.623-.046H16.67Zm25.833 4.405V16.5a4.167 4.167 0 0 0 4.166 4.167h4.663l-8.83-10.595ZM.836 16.5C.836 7.755 7.925.667 16.669.667H36.88c2.72 0 5.3 1.208 7.042 3.298l13.123 15.747a9.166 9.166 0 0 1 2.124 5.868V56.5c0 8.745-7.089 15.833-15.833 15.833H16.669C7.925 72.333.836 65.245.836 56.5v-40Zm13.333 20a2.5 2.5 0 0 1 2.5-2.5h26.667a2.5 2.5 0 0 1 0 5H16.669a2.5 2.5 0 0 1-2.5-2.5Zm0 16.667a2.5 2.5 0 0 1 2.5-2.5h13.334a2.5 2.5 0 0 1 0 5H16.669a2.5 2.5 0 0 1-2.5-2.5Z"
    />
  </svg>
)
export default FileIcon2
