import { SVGProps } from "react"
const JobLocationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || "28"}
    height={props.height || "28"}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.9996 25.1998C13.9996 25.1998 22.7648 17.4085 22.7648 11.565C22.7648 6.72413 18.8405 2.7998 13.9996 2.7998C9.1587 2.7998 5.23438 6.72413 5.23438 11.565C5.23438 17.4085 13.9996 25.1998 13.9996 25.1998Z"
      stroke={props.color || "#5C6474"}
      strokeWidth="1.4"
    />
    <path
      d="M16.8 11.2C16.8 12.7464 15.5463 14 14 14C12.4536 14 11.2 12.7464 11.2 11.2C11.2 9.65358 12.4536 8.39998 14 8.39998C15.5463 8.39998 16.8 9.65358 16.8 11.2Z"
      stroke={props.color || "#5C6474"}
      strokeWidth="1.4"
    />
  </svg>
)
export default JobLocationIcon
