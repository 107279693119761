export function getUmbracoMediaUrl(url?: string, onlyMedia?: boolean) {
  const GRAPHQL_URL = process.env.GRAPHQL_URL?.replace("/graphql", "")

  if (!url) {
    return ""
  }
  if (onlyMedia) {
    return GRAPHQL_URL + "/" + url
  }
  // replace url before /media/ with graphql url
  const updatedUrl = url?.replace(
    /(https?:\/\/[^\/]+\/media\/)/,
    GRAPHQL_URL + "/media/"
  )

  return updatedUrl
}
