interface PropertyValue {
  value?: string
  richText?: string
  blocks?: any[] // Adjust the type as per your data structure if needed
  mediaItems?: { url: string }[]
  links?: []
  dateTime?: string
  contentList?: any
}

interface DataProperty {
  propertyName: string | null
  propertyValue: PropertyValue | null
}

interface Data {
  properties?: DataProperty[]
}

export function extractProperty(
  data: Data | any,
  propertyName: string,
  allMedia?: boolean
): any {
  if (!data || !propertyName) {
    return ""
  }

  const property = data.properties?.find(
    (prop: DataProperty) => prop.propertyName === propertyName
  )

  if (!property?.propertyValue) {
    return ""
  }
  if (allMedia) {
    return property.propertyValue.mediaItems
  }

  // Attempt extraction in the given order
  return (
    property.propertyValue?.value ||
    property.propertyValue?.richText ||
    property.propertyValue?.mediaItems?.[0]?.url ||
    property.propertyValue.blocks || // Assuming blocks is an array you might want to convert to string
    property.propertyValue.links ||
    property.propertyValue.dateTime ||
    property.propertyValue.contentList ||
    ""
  )
}
