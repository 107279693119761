import { GetPageQuery } from "apollo"
import { getUmbracoMediaUrl } from "."
import { extractProperty } from "./extractProperty"

export type MetaData = {
  pageSlug: string | undefined
  metaTitle: string | undefined
  metaKeywords: string | undefined
  metaDescription: string | undefined
  robotsTxt: string | undefined
  canonicalUrl: string | undefined
  oldUrls: string[]
}
export type Category = {
  metaData: MetaData
}

export function getMataTag(object: any) {
  return {
    pageSlug: extractProperty(object, "pageslug") as string | undefined,
    metaTitle: extractProperty(object, "metaTitle") as string | undefined,
    metaKeywords: extractProperty(object, "metaKeywords") as string | undefined,
    metaDescription: extractProperty(object, "metaDescription") as
      | string
      | undefined,
    robotsTxt:
      (extractProperty(object, "robotsTxt") as string | undefined) ||
      process.env.ENV?.includes("production")
        ? undefined
        : {
            index: false,
            follow: false,
          },
    canonicalUrl: extractProperty(object, "canonicalUrl") as string | undefined,
    oldUrls: extractProperty(object, "oldUrls") as string[],
    titleBreadcrumbs: extractProperty(object, "titleBreadcrumbs") as
      | string
      | undefined,
    ogImage: getUmbracoMediaUrl(
      extractProperty(object, "ogImageSHARE") as string | undefined
    ),
  }
}
type Props = {
  itemsData: GetPageQuery
  infoBlock?: boolean
}
export function extractMetaTags({ itemsData }: Props) {
  let object = itemsData.page
  const pageBlock = extractProperty(object, "pageInfoAndMetaTags")
  const pageInfo = extractProperty(object, "pageInfoBlockContent")
  if (pageBlock) {
    object = pageBlock?.[0]
  } else if (pageInfo) {
    object = pageInfo?.[0]
  }
  const metaData = getMataTag(object)
  return metaData
}
