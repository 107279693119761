import styled from "@emotion/styled"
import { Backdrop, Collapse } from "@mui/material"
import { FetchMenusQuery } from "apollo"
import { useLocale } from "next-intl"
import Link from "next/link"
import { usePathname } from "next/navigation"
import { useState } from "react"
import { extractMenuProperties, getNonUmbracoUrl, mainMenuClick } from "utils"
import NavLink from "../NavLink/NavLink"
import { Mobile } from "../common/bit/MediaQueries"

const StyledMenu = styled(Collapse)`
  min-height: -webkit-fill-available;
  position: fixed;

  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 100000;
  background-color: #fff;
  width: 100%;
  border-end-start-radius: 20px;
  border-end-end-radius: 20px;
`

const StyledMenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 64px;
  align-items: center;
`

export const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0px;
`
export const StyledList = styled.nav`
  display: flex;
  flex-direction: column;
  padding: 0px;
  padding-bottom: 22px;
  height: 100dvh;
  overflow: scroll;
  ${(props) => props.theme.breakpoints.up("md")} {
    max-width: 825px;
    overflow-y: hidden;
    overflow-x: auto;
    justify-content: space-between;
    flex-direction: row;
    height: auto;
    padding: 0px;
    gap: 25px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    max-width: 1055px;
  }
`
export const StyledLinkHeb = styled(Link)<{ selected: boolean }>`
  text-decoration: none;
  font-style: normal;
  font-weight: ${(props) => (props.selected ? "500" : "400")};
  font-size: 16px;
  line-height: 22px;

  color: ${(props) => props.theme.colors.black};
`

export const StyledLinkEn = styled(Link)<{ selected: boolean }>`
  position: relative;
  top: 1px;
  text-decoration: none;

  font-style: normal;
  font-weight: ${(props) => (props.selected ? "900" : "400")};
  font-size: 14px;
  line-height: 20px;

  color: ${(props) => props.theme.colors.black};
`
export const StyledLink = styled(Link)<{ selected?: boolean }>`
  display: flex;
`
export const StyledImageAndName = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 30px;
  padding: 0px 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-bottom: 0px;
  }
`

type Props = {
  // eslint-disable-next-line no-unused-vars
  setOpen: (open: boolean) => void
  open: boolean
  menuItems: FetchMenusQuery
}

const SideMenu = ({ menuItems, open, setOpen }: Props) => {
  const locale = useLocale()
  const pathname = usePathname()
  const [openedMenuIndex, setOpenedMenuIndex] = useState<number>(-1)

  return (
    <Mobile>
      <StyledMenu in={open}>
        <StyledMenuHeader></StyledMenuHeader>

        <StyledList role="navigation">
          <NavLink
            onClick={() => {
              setOpen(false)
              mainMenuClick("עמוד הבית", "")
            }}
            href={locale === "he" ? "/" : "/en"}
            selected={decodeURI(pathname) === "/" || decodeURI(pathname) === "/en"}
            title={locale === "he" ? "עמוד הבית" : "Home Page"}
            isOpen={openedMenuIndex === 999}
            setOpen={() => {
              if (openedMenuIndex === 999) {
                setOpenedMenuIndex(-1)
              } else {
                setOpenedMenuIndex(999)
              }
            }}
          />
          {menuItems?.menu_desktop?.children?.map((item: any, index: number) => {
            const { title, url } = extractMenuProperties(item)
            const decodedPathname = decodeURI(pathname) + "/"

            const subActive = item?.children?.some(
              (sub: any) => getNonUmbracoUrl(extractMenuProperties(sub).url) === decodedPathname,
            )
            const subSubActive = item?.children?.some((sub: any) =>
              sub?.children?.some(
                (subSub: any) =>
                  getNonUmbracoUrl(extractMenuProperties(subSub).url) === decodedPathname,
              ),
            )
            return (
              <NavLink
                onClick={() => {
                  setOpen(false)
                  item?.children?.length === 0 && mainMenuClick(title, "")
                }}
                key={index.toString()}
                href={getNonUmbracoUrl(url)}
                selected={
                  decodeURI(pathname) + "/" === getNonUmbracoUrl(url) || subActive || subSubActive
                }
                title={title}
                subCategories={item?.children}
                isOpen={openedMenuIndex === index}
                setOpen={() => {
                  if (openedMenuIndex === index) {
                    setOpenedMenuIndex(-1)
                  } else {
                    setOpenedMenuIndex(index)
                  }
                }}
              />
            )
          })}
          <Mobile>
            <NavLink
              onClick={() => {
                setOpen(false)
                mainMenuClick(locale === "he" ? "English" : "עִברִית", "")
              }}
              href={locale === "he" ? "/en" + pathname : pathname.replace("/en", "/he")}
              selected={false}
              title={locale === "he" ? "English" : "עִברִית"}
            />
          </Mobile>
        </StyledList>
      </StyledMenu>
      <Backdrop open={open} sx={{ color: "#fff", zIndex: -1 }} onClick={() => setOpen(false)} />
    </Mobile>
  )
}

export default SideMenu
