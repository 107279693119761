"use client"
import styled from "@emotion/styled"
import { SVGProps } from "react"

const StyledBox = styled.div<{ color?: string }>`
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: ${(props) => (props.color ? props.color : "black")};
`
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="m13.333 4-7.334 7.333L2.666 8"
    />
  </svg>
)

function CheckedCheckboxIcon({ color }: { color?: string }) {
  return (
    <StyledBox color={color}>
      <SvgComponent />
    </StyledBox>
  )
}

export default CheckedCheckboxIcon
