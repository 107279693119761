export function findProperty(obj: any, propertyName: string): any {
  if (!obj || typeof obj !== "object") {
    return null
  }

  // Check if the object has 'properties' array
  if (Array.isArray(obj.properties)) {
    // Loop through the array to find the property with the specified propertyName
    for (const property of obj.properties) {
      if (property.propertyName === propertyName) {
        return property.propertyValue
      }
    }
  }

  // Recurse into child objects if the property is not found at this level
  for (const key in obj) {
    const result = findProperty(obj[key], propertyName)
    if (result !== null) {
      return result
    }
  }

  return null
}
