"use client"

import styled from "@emotion/styled"
import Link from "next/link"
import { useEffect, useState } from "react"
import { getNonUmbracoUrl } from "utils"
import { Desktop, Mobile } from "../common/bit/MediaQueries"
import ImageWithAlt from "../ImageWithAlt"
import NewText from "../NewText/NewText"

const StyledWrapper = styled.div`
  margin: auto;
  position: relative;
`

const AccordionImage = styled.div<{ height: string }>`
  --_button-size: 3rem;
  --_panel-padding: 0.75rem;
  --_panel-gap: 1rem;

  display: flex;
  flex-direction: column;
  gap: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 8px;
    flex-direction: row;
    height: ${(props) => props.height};
  }
`

const AccordionPanel = styled.div`
  position: relative;
  isolation: isolate;
  flex-basis: clamp(100px, 100px, 200px);
  ${(props) => props.theme.breakpoints.up("md")} {
    flex-basis: clamp(100px, 10%, 200px);
  }
  overflow: hidden;
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background-color: rgba(44, 91, 163, 0.4);
    transition: opacity 500ms;
    opacity: 0;
  }
  &:not(:has([aria-expanded="true"]))::after {
    opacity: 1;
  }
  &:focus-within {
    outline: 3px solid black;
    outline-offset: 4px;
  }

  @media (prefers-reduced-motion: no-preference) {
    transition: flex-basis 1s, flex-grow 1s;
  }

  &:has([aria-expanded="true"]) {
    flex-basis: clamp(15rem, 420px, 420px);
    height: 420px;
  }
`

const AccordionTrigger = styled.button`
  outline: 0 !important;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: var(--_panel-gap);
  background: transparent;
  border: 0;
  padding: 0;
`

const AccordionContent = styled.div<{ ariaExpanded: boolean }>`
  height: 100%;
  display: flex;
  align-items: end;
`

const Content = styled(Link)<{ ariaExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-decoration: none;
  z-index: 100;
`

const AccordionImageInner = styled(ImageWithAlt)<{ ariaExpanded: boolean }>`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`

const LogoContainer = styled.div<{ active: boolean }>`
  display: flex;
  width: 64px;
  overflow: hidden;
  height: 64px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  border: 2px solid white;
  background: ${(props) => (props.active ? "white" : "rgba(255, 255, 255, 0.15)")};
  backdrop-filter: ${(props) => (props.active ? "" : "blur(2.3333334922790527px)")};
  z-index: 100;
`
const StyledOverlay = styled.div<{ $showContent: boolean }>`
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 30%,
    rgba(0, 0, 0, 0.6) 100%
  );
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  z-index: 1;
  border-radius: 12px;
  transition: all 0.3s ease-in-out;
  opacity: ${(props) => (props.$showContent ? 1 : 0)};
  visibility: ${(props) => (props.$showContent ? "visible" : "hidden")};
`

const StyledImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
`

export type AccordionItem = {
  id: number
  logo?: { url: string; altText: string }
  transparentLogo?: string
  image?: { url: string; altText: string }
  imageMobile?: { url: string; altText: string }
  title?: string
  content?: string
  link?: string
}

type Props = {
  accordionData: AccordionItem[]
  height: string
}

function AccordionItem({
  item,
  onClick,
  currentActive,
}: {
  item: AccordionItem
  currentActive: number | null
  // eslint-disable-next-line no-unused-vars
  onClick: (id: number) => void
}) {
  const active = currentActive === item.id
  const [showContent, setShowContent] = useState(false)
  useEffect(() => {
    if (!active) {
      setShowContent(false)
    }
    setTimeout(() => {
      if (active) {
        setShowContent(true)
      }
    }, 600)
  }, [active])

  return (
    <AccordionPanel key={item.id} onClick={() => onClick(item.id)}>
      <AccordionTrigger
        type="button"
        aria-controls="panel1-content"
        aria-label="open-tab"
        aria-expanded={active}
      />
      <AccordionContent ariaExpanded={active} aria-hidden={!active} role="region">
        {showContent && (
          <Content ariaExpanded={active} href={getNonUmbracoUrl(item.link)} target="_blank">
            <LogoContainer active={active}>
              <ImageWithAlt
                image={item.logo}
                width={65}
                height={65}
                style={{ borderRadius: "200px", objectFit: "cover" }}
              />
            </LogoContainer>
            <NewText component={"h3"} desktopFontSize={32} desktopLineHeight={"36px"} color="white">
              {item.title}
            </NewText>
            <NewText component={"p"} desktopFontSize={20} desktopLineHeight={"29px"} color="white">
              {item.content}
            </NewText>
          </Content>
        )}
        {!active ? (
          <LogoContainer active={active}>
            <ImageWithAlt
              image={item.logo}
              width={65}
              height={65}
              style={{ borderRadius: "200px", objectFit: "cover" }}
            />
          </LogoContainer>
        ) : null}
        <StyledImageWrapper>
          <StyledOverlay $showContent={showContent} />

          <Desktop>
            <AccordionImageInner fill ariaExpanded={active} image={item.image} />
          </Desktop>
          <Mobile>
            <AccordionImageInner fill ariaExpanded={active} image={item.imageMobile} />
          </Mobile>
        </StyledImageWrapper>
      </AccordionContent>
    </AccordionPanel>
  )
}

function UseImageAccordion({ accordionData, height }: Props) {
  const [activePanel, setActivePanel] = useState<null | number>(null)
  function toggleAccordion(panelToActivate: number) {
    setActivePanel((prevPanel) => {
      if (prevPanel === panelToActivate) {
        return prevPanel
      } else {
        return panelToActivate
      }
    })
  }

  useEffect(() => {
    setActivePanel(accordionData[0].id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!accordionData) return null
  return (
    <>
      <StyledWrapper>
        <AccordionImage height={height}>
          {accordionData.map((item) => {
            return (
              <AccordionItem
                key={item.id}
                currentActive={activePanel}
                item={item}
                onClick={toggleAccordion}
              />
            )
          })}
        </AccordionImage>
      </StyledWrapper>
    </>
  )
}

export default UseImageAccordion
