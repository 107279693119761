const GalleryIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_17529_11181)">
      <rect x="8" y="4" width="32" height="32" rx="16" fill="white" />
      <path
        d="M20.6154 27.3846L17 27.3846C16.4477 27.3846 16 26.9369 16 26.3846L16 22.7692M16.7692 26.6155L22.1538 21.2309M31.3844 16.6151L31.3844 12.9998C31.3844 12.4475 30.9367 11.9998 30.3844 11.9998L26.769 11.9998M30.6152 12.769L25.2305 18.1536"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_17529_11181"
        x="0"
        y="0"
        width="48"
        height="48"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_17529_11181" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_17529_11181"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
export default GalleryIcon
