type BasicProperty = {
  __typename: "BasicProperty"
  property: BasicPropertyValue | BasicContentPicker
}

type BasicPropertyValue = {
  __typename: "BasicPropertyValue"
  menuName: string
}

type BasicContentPicker = {
  __typename: "BasicContentPicker"
  menuTarget: BasicContentPickerItem[]
}

type BasicContentPickerItem = {
  __typename: "BasicContentPickerItem"
  id: number
  absoluteUrl: string
  key: string
  name: string
  url: string
  urlSegment: string
}

type InputType = {
  __typename: "BasicContent"
  children: any[]
  properties: BasicProperty[]
}

export function extractMenuProperties(item: InputType) {
  let url: string = ""
  let title: string = ""
  let name: string = ""
  if (item?.properties?.[0]?.property?.__typename === "BasicPropertyValue") {
    title = item.properties[0].property.menuName
  }

  if (item?.children?.[0]?.properties?.[1].property?.menuTarget?.[0]?.name) {
    name = item?.children[0]?.properties?.[1].property.menuTarget?.[0].name
  }
  if (
    item?.properties?.[1]?.property?.__typename === "BasicPropertyValue" &&
    item?.properties?.[1]?.property?.menuName
  ) {
    url = item.properties[1].property.menuName || ""
    return { url, title, name }
  }
  if (item?.properties?.[1]?.property?.__typename === "BasicContentPicker") {
    url =
      item.properties[1].property.menuTarget[0]?.url?.replace("/pazcorp", "") ||
      ""
  }

  if (item?.properties?.[0]?.property?.__typename === "BasicPropertyValue") {
    title = item.properties[0].property.menuName
  }

  return { url, title, name }
}
