"use client"
import IconButton from "@mui/material/IconButton"
import InputBase from "@mui/material/InputBase"
import Paper from "@mui/material/Paper"
import { SubmitSearchIcon } from "../icons"
import { sharedColors } from "../theme/colors"

type Props = {
  placeholder: string
}

function SearchInput({ placeholder }: Props) {
  return (
    <Paper
      component="form"
      sx={{
        p: "8px 8px 8px 16px",
        borderRadius: "200px",
        border: "1px solid #6F6F6F",
        display: "flex",
        alignItems: "center",
        height: "48px",
        "& ::placeholder": {
          color: sharedColors.lightGray,
        },
      }}
    >
      <InputBase
        sx={{
          ml: "8px",
          flex: 1,
          color: sharedColors.darkBlue,
          "&:placeholder": {
            color: sharedColors.lightGray,
          },
        }}
        placeholder={placeholder}
        inputProps={{ "aria-label": placeholder }}
      />

      <IconButton
        sx={{ p: "0px", color: sharedColors.propBlue }}
        aria-label="directions"
      >
        <SubmitSearchIcon />
      </IconButton>
    </Paper>
  )
}

export default SearchInput
