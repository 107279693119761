import { SVGProps } from "react"

const MuteHover = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_362_11277)">
        <rect x="8" y="4" width="32" height="32" rx="16" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.9756 15.4321C24.9756 14.6168 24.0352 14.1785 23.4294 14.7115L20.9392 16.9025C20.5655 17.2313 20.0883 17.4122 19.5946 17.4122L18.4309 17.4122C17.708 17.4122 17.122 18.0091 17.122 18.7454V21.254C17.122 21.9903 17.708 22.5872 18.4309 22.5872H19.5946C20.0883 22.5872 20.5656 22.7681 20.9392 23.0969L23.4294 25.2879C24.0352 25.8209 24.9756 25.3826 24.9756 24.5673V19.9997V15.4321ZM22.696 13.8467C24.0287 12.6741 26.0976 13.6384 26.0976 15.4321V19.9997V24.5673C26.0976 26.361 24.0287 27.3252 22.696 26.1527L20.2058 23.9616C20.0359 23.8122 19.819 23.73 19.5946 23.73H18.4309C17.0883 23.73 16 22.6214 16 21.254V18.7454C16 17.378 17.0883 16.2694 18.4309 16.2694L19.5946 16.2694C19.819 16.2694 20.0359 16.1872 20.2057 16.0378L22.696 13.8467ZM27.4435 17.2578C27.6912 17.0682 28.0429 17.1191 28.229 17.3714C28.7691 18.1035 29.0894 19.0145 29.0894 20.0001C29.0894 20.9856 28.7691 21.8966 28.229 22.6288C28.0429 22.881 27.6912 22.9319 27.4435 22.7423C27.1958 22.5528 27.1459 22.1946 27.332 21.9423C27.7312 21.4012 27.9675 20.7295 27.9675 20.0001C27.9675 19.2706 27.7312 18.5989 27.332 18.0578C27.1459 17.8055 27.1958 17.4474 27.4435 17.2578ZM29.7249 15.0859C29.5388 14.8336 29.1871 14.7828 28.9394 14.9723C28.6917 15.1619 28.6418 15.5201 28.8279 15.7723C29.6968 16.95 30.2114 18.4131 30.2114 20.0001C30.2114 21.5871 29.6968 23.0501 28.8279 24.2278C28.6418 24.48 28.6917 24.8382 28.9394 25.0278C29.1871 25.2173 29.5388 25.1665 29.7249 24.9142C30.7347 23.5454 31.3333 21.8431 31.3333 20.0001C31.3333 18.157 30.7347 16.4547 29.7249 15.0859Z"
          fill="black"
        />
        <path
          d="M23.4294 14.7115C24.0352 14.1785 24.9756 14.6168 24.9756 15.4321V19.9997V24.5673C24.9756 25.3826 24.0352 25.8209 23.4294 25.2879L20.9392 23.0969C20.5656 22.7681 20.0883 22.5872 19.5946 22.5872H18.4309C17.708 22.5872 17.122 21.9903 17.122 21.254V18.7454C17.122 18.0091 17.708 17.4122 18.4309 17.4122L19.5946 17.4122C20.0883 17.4122 20.5655 17.2313 20.9392 16.9025L23.4294 14.7115Z"
          fill="black"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_362_11277"
          x="0"
          y="0"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_362_11277"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_362_11277"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default MuteHover
