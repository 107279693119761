"use client"

import styled from "@emotion/styled"
import { Button } from "@mui/material"
import Image from "next/image"
import Link from "next/link"
import { useState } from "react"

import { Navigation } from "swiper"
import "swiper/css"
import { Swiper, SwiperSlide } from "swiper/react"
import { getUmbracoMediaUrl } from "utils"
import NewText from "./NewText/NewText"
import { Desktop, Mobile } from "./common/bit/MediaQueries"
import { LeftArrow, LeftMobileArrow, ReadMoreIcon, RightArrow, RightMobileArrow } from "./icons"
import { sharedColors } from "./theme/colors"

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${(props) => props.theme.breakpoints.up("md")} {
    grid-template-columns: 1fr 1fr;
  }
`

const StyledImageContainer = styled.div<{
  withRightImage: boolean
}>`
  width: 100%;
  height: 375px;
  position: relative;

  ${(props) => props.theme.breakpoints.up("md")} {
    height: 900px;
    order: ${(props) => (props.withRightImage ? "0" : "1")};
  }
`

const StyledTextContainer = styled.div<{ withRightImage: boolean }>`
  margin: 28px auto;
  width: 375px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  ${(props) => props.theme.breakpoints.up("md")} {
    margin: 120px;
    width: 620px;
    height: 640px;
    justify-self: ${(props) => (props.withRightImage ? "flex-start" : "flex-end")};
  }
`

const StyledCTA = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${sharedColors.blue};
  text-decoration: none;
  margin-top: 20px;
  font-size: 16px;
  line-height: 20px;
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 18px;
    line-height: 25.2px;
    margin-top: 16px;
  }
`
const StyledSlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
const StyledArrowsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 0px;
  z-index: 9;
  right: 0px;
  top: 14px;
  ${(props) => props.theme.breakpoints.up("md")} {
    position: relative;
    top: 0px;
  }
`

const StyledArrowTextContainer = styled.div`
  width: 22px;
  text-align: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 48px;
  }
`

const StyledArrowContainer = styled(Button)`
  padding: 0px;
  min-width: auto;
`

const StyledSlideIconContainer = styled.div`
  position: relative;
  height: 50px;

  margin-bottom: 18px;
  img {
    width: auto !important;
  }
`

const StyledTagsContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`

const StyledTag = styled.div`
  padding: 8px 20px;
  border: 2px solid ${sharedColors.blue};
  border-radius: 20px;
  height: 40px;
`

type Props = {
  data: any
  keyForSlider: string
  withCover?: boolean
}

export const BrandsValue: React.FC<Props> = ({ data, keyForSlider, withCover }) => {
  const innerSliderBlocks = data?.properties?.find(
    (p: any) => p.propertyName === "innerSliderImageNextToText",
  ).propertyValue.blocks

  const withRightImage = data?.properties?.find((p: any) => p.propertyName === "flipSide")
    .propertyValue.value

  const items = innerSliderBlocks.map((block: any) => {
    const { properties } = block

    const image = properties.find((p: any) => p.propertyName === "imgText").propertyValue
      .mediaItems[0]

    const logo = properties.find((p: any) => p.propertyName === "logo").propertyValue.mediaItems[0]
    const slidTitle = properties.find((p: any) => p.propertyName === "slidTitle").propertyValue
      .richText
    const slidContent = properties.find((p: any) => p.propertyName === "slidContent").propertyValue
      .richText
    const slidfUrl = properties.find((p: any) => p.propertyName === "slidfUrl")?.propertyValue
      .links[0]

    return {
      bigImage: {
        url: image.url,
        alt: image.id.toString(),
      },
      icon: {
        url: logo.url,
        alt: logo.id.toString(),
      },
      title: slidTitle,
      subtitle: slidContent,
      cta: {
        url: slidfUrl?.url,
        text: slidfUrl?.name,
        target: slidfUrl?.target,
      },
      tags: [],
    }
  })

  const [activeIndex, setActiveIndex] = useState(0)
  return (
    <Swiper
      navigation={{
        nextEl: `.arrow-left-${keyForSlider}`,
        prevEl: `.arrow-right-${keyForSlider}`,
      }}
      key={1}
      style={{ padding: 0 }}
      modules={[Navigation]}
      slidesPerView={1}
      onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
    >
      {items.map((item: any, index: number) => (
        <SwiperSlide key={index.toString()}>
          <StyledGrid>
            <StyledImageContainer withRightImage={withRightImage}>
              <Image
                style={{ objectFit: withCover ? "cover" : "contain" }}
                src={getUmbracoMediaUrl(item?.bigImage?.url)}
                alt={item?.bigImage?.alt}
                fill
              />
            </StyledImageContainer>
            <StyledTextContainer withRightImage={withRightImage}>
              <StyledSlideContainer>
                <StyledSlideIconContainer>
                  <Image
                    style={{ objectFit: "contain" }}
                    src={getUmbracoMediaUrl(item.icon.url)}
                    alt={item.icon.alt}
                    fill
                  />
                </StyledSlideIconContainer>
                <NewText
                  mobileFontSize={28}
                  mobileLineHeight="128%"
                  desktopFontSize={48}
                  component="div"
                  desktopLineHeight="140%"
                  desktopFontWeight="500"
                  color={sharedColors.black}
                  dangerouslySetInnerHTML={{ __html: item.title }} // For rich text rendering
                />
                <NewText
                  mobileFontSize={18}
                  desktopFontSize={24}
                  component="div"
                  desktopLineHeight="140%"
                  desktopFontWeight="400"
                  color={sharedColors.lighterBlack}
                  dangerouslySetInnerHTML={{ __html: item.subtitle }} // For rich text rendering
                />
                <StyledTagsContainer>
                  {item.tags.map((tag: any, index: number) => (
                    <StyledTag key={index.toString()}>
                      <NewText
                        desktopFontSize={16}
                        component="p"
                        desktopLineHeight="150%"
                        desktopFontWeight="400"
                        color={sharedColors.blue}
                      >
                        {tag}
                      </NewText>
                    </StyledTag>
                  ))}
                </StyledTagsContainer>
                <StyledCTA target={item.cta.target} href={item.cta.url}>
                  {item.cta.text}

                  <ReadMoreIcon />
                </StyledCTA>
              </StyledSlideContainer>
              {items.length > 1 && (
                <StyledArrowsContainer>
                  <StyledArrowContainer className={`arrow-left-${keyForSlider} arrow`}>
                    <Desktop>
                      <RightArrow />
                    </Desktop>
                    <Mobile>
                      <RightMobileArrow />
                    </Mobile>
                  </StyledArrowContainer>
                  <StyledArrowTextContainer>
                    <Desktop>
                      <NewText
                        desktopFontSize={16}
                        component="p"
                        desktopLineHeight="150%"
                        desktopFontWeight="400"
                        color={sharedColors.darkGray}
                      >
                        {activeIndex + 1}/{items.length}
                      </NewText>
                    </Desktop>
                    <Mobile>
                      <NewText
                        desktopFontSize={14}
                        component="p"
                        desktopLineHeight="20px"
                        desktopFontWeight="400"
                        color={sharedColors.darkGray}
                      >
                        {activeIndex + 1}/{items.length}
                      </NewText>
                    </Mobile>
                  </StyledArrowTextContainer>
                  <StyledArrowContainer className={`arrow-right-${keyForSlider} arrow`}>
                    <Desktop>
                      <LeftArrow />
                    </Desktop>
                    <Mobile>
                      <LeftMobileArrow />
                    </Mobile>
                  </StyledArrowContainer>
                </StyledArrowsContainer>
              )}
            </StyledTextContainer>
          </StyledGrid>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default BrandsValue
