import React, { SVGProps } from "react"
import Mute from "./Mute"
import MuteHover from "./MuteHover"

const VideoMuteIcon = (props: SVGProps<SVGSVGElement>) => {
  const [isHovered, setIsHovered] = React.useState(false)
  return (
    <span
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      {!isHovered ? <Mute {...props} /> : <MuteHover {...props} />}
    </span>
  )
}

export default VideoMuteIcon
