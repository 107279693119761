import { SVGProps } from "react"
const CheckedCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_18496_77705"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="18"
      height="18"
    >
      <rect width="18" height="18" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_18496_77705)">
      <path
        d="M9 16.5C7.9625 16.5 6.9875 16.3031 6.075 15.9094C5.1625 15.5156 4.36875 14.9813 3.69375 14.3063C3.01875 13.6313 2.48438 12.8375 2.09063 11.925C1.69687 11.0125 1.5 10.0375 1.5 9C1.5 7.9625 1.69687 6.9875 2.09063 6.075C2.48438 5.1625 3.01875 4.36875 3.69375 3.69375C4.36875 3.01875 5.1625 2.48438 6.075 2.09063C6.9875 1.69687 7.9625 1.5 9 1.5C9.8125 1.5 10.5813 1.61875 11.3062 1.85625C12.0312 2.09375 12.7 2.425 13.3125 2.85L12.225 3.95625C11.75 3.65625 11.2438 3.42188 10.7063 3.25313C10.1688 3.08438 9.6 3 9 3C7.3375 3 5.92188 3.58438 4.75313 4.75313C3.58438 5.92188 3 7.3375 3 9C3 10.6625 3.58438 12.0781 4.75313 13.2469C5.92188 14.4156 7.3375 15 9 15C10.6625 15 12.0781 14.4156 13.2469 13.2469C14.4156 12.0781 15 10.6625 15 9C15 8.775 14.9875 8.55 14.9625 8.325C14.9375 8.1 14.9 7.88125 14.85 7.66875L16.0688 6.45C16.2063 6.85 16.3125 7.2625 16.3875 7.6875C16.4625 8.1125 16.5 8.55 16.5 9C16.5 10.0375 16.3031 11.0125 15.9094 11.925C15.5156 12.8375 14.9813 13.6313 14.3063 14.3063C13.6313 14.9813 12.8375 15.5156 11.925 15.9094C11.0125 16.3031 10.0375 16.5 9 16.5ZM7.95 12.45L4.7625 9.2625L5.8125 8.2125L7.95 10.35L15.45 2.83125L16.5 3.88125L7.95 12.45Z"
        fill="#2768A3"
      />
    </g>
  </svg>
)
export default CheckedCircleIcon
