import { SVGProps } from "react"
const ArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M19 9L12.7809 14.3306C12.3316 14.7158 11.6684 14.7158 11.2191 14.3306L5 9"
      stroke={props.stroke || "black"}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)
export default ArrowDown
